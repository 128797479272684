import React from 'react';
import { Routes as Rote, Route } from 'react-router-dom';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import ForgotPassword from '../pages/ForgotPassword';
import NotFound from '../pages/NotFound';
import Dashboard from '../pages/Dashboard';
import { Authorization } from '../components/Auth';

const Routes = () => (
  <Rote>
    <Route path="/" element={<SignIn />} />
    <Route path="/signup" element={<SignUp />} />
    <Route path="/forgot-password" element={<ForgotPassword />} />
    <Route
      path="/dashboard/*"
      element={
        <Authorization>
          <Dashboard />
        </Authorization>
      }
    />
    <Route path="*" element={<NotFound />} />
  </Rote>
);

export default Routes;
