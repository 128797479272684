/* eslint-disable no-restricted-syntax */
/* eslint-disable no-restricted-properties */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-shadow */
/* eslint-disable prefer-const */
/* eslint-disable no-console */
/* eslint-disable no-unreachable */
/* eslint-disable react/prop-types */
/* eslint-disable function-paren-newline */
import intersect from '@turf/intersect';
import { getPointResolution } from 'ol/proj';
import DoubleClickZoom from 'ol/interaction/DoubleClickZoom';
import * as turf from '@turf/turf';
import Swipe from 'ol-ext/control/Swipe';
import * as control from 'ol/control';
import { doubleClick, pointerMove } from 'ol/events/condition';
import * as olExtent from 'ol/extent';
import GeoJSON from 'ol/format/GeoJSON';
import WKT from 'ol/format/WKT';
import Draw from 'ol/interaction/Draw';
import Modify from 'ol/interaction/Modify';
import Select from 'ol/interaction/Select';
import {
  Group as LayerGroup,
  Tile as TileLayer,
  Vector as VectorLayer
} from 'ol/layer';
import Map from 'ol/Map';
import { unByKey } from 'ol/Observable';
import { TileWMS, Vector as VectorSource } from 'ol/source';
import XYZ from 'ol/source/XYZ';
import { Fill, Icon, Stroke, Style, Text, Circle } from 'ol/style';
import View from 'ol/View';
import { transparentize } from 'polished';
import proj4 from 'proj4';
import { createContext, useContext, useState, React } from 'react';
import { toast } from 'react-toastify';
import Toast from '../../components/Toast';
import {
  parseCoordinatesLineStringTo4326,
  parseCoordinatesPointTo3857
} from '../../helpers/parseCoordinates';
import { depthImages } from '../../constants/depthImages';
import { useAuth } from '../Auth';
import { appConfig } from '../../config';
import { ThreadUtils } from '../../util/Thread.Utils';

const MapContext = createContext();

const MapProvider = ({ children }) => {
  const [mapCenter, setMapCenter] = useState([-6000000, -1900000]);
  const [showChat, setShowChat] = useState(false);
  const [swipeShow, setSwipeShow] = useState(false);
  const [refMap, setRefMap] = useState(new Map());
  const [refMap1, setRefMap1] = useState();
  const [refMap2, setRefMap2] = useState();
  const [refMap3, setRefMap3] = useState();
  const [refMap4, setRefMap4] = useState();
  const [drawing, setDrawing] = useState(false);
  const [orientation, setOrientation] = useState('Retrato');
  const [stateOptions, setStateOptions] = useState(true);
  const [farm, setFarm] = useState();
  const [field, setField] = useState();
  const [subdivision, setSubdivision] = useState();
  const [drawendSubdivision, setDrawendSubdivision] = useState(true);
  const [drawendSubdivisionAndPoint, setDrawendSubdivisionAndPoint] =
    useState(true);
  const [draw, setDraw] = useState();
  const [modify, setModify] = useState();
  const [selecter, setSelecter] = useState();
  const [viewFields, setViewFields] = useState(false);
  const [addingField, setAddingField] = useState(false);
  const [drawendField, setDrawendField] = useState(false);
  const [selectedFarmId, setSelectedFarmId] = useState('');
  const [farms, setFarms] = useState([]);
  const [geometryList, setGeometryList] = useState([]);
  const [listFields, setListFields] = useState([]);
  const [listSubdivisions, setListSubdivisions] = useState([]);
  const [editedMapping, setEditedMapping] = useState();
  const [dateCarrousel, setDateCarrousel] = useState();
  const [activeLayers, setActiveLayers] = useState([]);
  const [subdivisionFieldsIntersect, setSubdivisionFieldsIntersect] = useState(
    []
  );
  const [disableDrawerButton, setDisableDrawerButton] = useState(false);
  const [showButtonCarousel, setShowButtonCarousel] = useState(false);
  const [showButtonChat, setShowButtonChat] = useState(false);
  const [btnColor, setBtnColor] = useState(false);
  const [controlState, setControlState] = useState();
  const [pointOfSubdivision, setPointOfSubdivision] = useState();
  const [selectedFieldToTimeline, setSelectedFieldToTimeline] = useState();
  const [walkOfSubdivision, setWalkOfSubdivision] = useState();
  const [editedWalkOfSubdivision, setEditedWalkOfSubdivision] = useState();
  const [importedShapefileName, setImportedShapefileName] = useState('');
  const [farmMapping, setFarmMapping] = useState();
  const [tileType, setTileType] = useState('NATURAL-COLOR');
  const [tilePreset, setTilePreset] = useState('NATURAL-COLOR');
  const [generatedCollectionPoints, setGeneratedCollectionPoints] = useState(
    []
  );
  const [generatedCollectionPointsQtd, setGeneratedCollectionPointsQtd] =
    useState([]);
  const [generatedMappingsSubdivisions, setGeneratedMappingsSubdivisions] =
    useState([]);
  const [legendResultsValue, setLegendResultsValue] = useState([]);
  const [legendResultsValue1, setLegendResultsValue1] = useState([]);
  const [legendResultsValue2, setLegendResultsValue2] = useState([]);
  const [legendResultsValue3, setLegendResultsValue3] = useState([]);
  const [legendResultsValue4, setLegendResultsValue4] = useState([]);
  const [legendRecommendationValue, setLegendRecommendationValue] = useState(
    []
  );
  const [legendRecommendationValue1, setLegendRecommendationValue1] = useState(
    []
  );
  const [legendRecommendationValue2, setLegendRecommendationValue2] = useState(
    []
  );
  const [legendRecommendationValue3, setLegendRecommendationValue3] = useState(
    []
  );
  const [legendRecommendationValue4, setLegendRecommendationValue4] = useState(
    []
  );
  const [recommendationId, setRecommendationId] = useState([]);
  const [selectedFarmHa, setSelectedFarmHa] = useState(0);
  const [selectedFarmGeoJson, setSelectedFarmGeoJson] = useState();
  const [renderedMaps, setRenderedMaps] = useState([]);
  const [reports, setReports] = useState([]);
  const [selectedRecommZone, setSelectedRecommZone] = useState();
  // const [recommZoneDrawed, setRecommZoneDrawed] = useState([]);
  let recommZoneDrawed = [];
  const [recommendationCompleted, setRecommendationCompleted] = useState(false);
  const [recommZoneList, setRecommZoneList] = useState([]);
  const [recommendationZoneId, setRecommendationZoneId] = useState('');
  const [recommMade, setRecommMade] = useState(true);
  const [renderedMapEditId, setRenderedMapEditId] = useState();
  const [reportEditId, setReportEditId] = useState();
  const [maxClouds, setMaxClouds] = useState(50);
  const [satellite, setSatellite] = useState('sentinel-2-l1c');
  const [recommZoneId, setRecommZoneId] = useState('');
  const [editZoneId, setEditZoneId] = useState(false);
  const geoJsonObj = new GeoJSON();
  const [selectedFields, setSelectedFields] = useState([]);
  const [editSub, setEditSub] = useState('');
  const [geomList, setGeomList] = useState([]);
  const [parameterResults, setParameterResults] = useState();
  const [qtdPoints, setQtdPoints] = useState(20);
  const [pointsDistance, setPointsDistance] = useState();
  const [offSetHeigth, setOffSetHeigth] = useState();
  const [resolution, setResolution] = useState(72);
  const [scale, setScale] = useState(10);
  const [tableResults, setTableResults] = useState([]);
  const [farmName, setFarmName] = useState('');
  const [selectedFieldsToNDVI, setSelectedFieldsToNDVI] = useState([]);
  const [recommDepth, setRecommDepth] = useState('');
  const [recommFertilizer, setRecommFertilizer] = useState('');
  const [recommDose, setRecommDose] = useState('');
  const [recommName, setRecommName] = useState('');
  const [recommType, setRecommType] = useState('corrective');
  const [recommMeasurementUnit, setRecommMeasurementUnit] = useState('litros');
  const [ownerName, setOwnerName] = useState('');
  const [
    selectedSubdivisionsForReccomendationZone,
    setSelectedSubdivisionsForReccomendationZone
  ] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [disableQueryNotification, setDisableQueryNotification] =
    useState(true);
  const [recommendationMapSubtitle, setRecommendationMapSubtitle] = useState(
    []
  );
  const [recommendationMapSubtitle1, setRecommendationMapSubtitle1] = useState(
    []
  );
  const [recommendationMapSubtitle2, setRecommendationMapSubtitle2] = useState(
    []
  );
  const [recommendationMapSubtitle3, setRecommendationMapSubtitle3] = useState(
    []
  );
  const [recommendationMapSubtitle4, setRecommendationMapSubtitle4] = useState(
    []
  );
  const [recommendationsZoneList, setRecommendationsZoneList] = useState([]);
  const [fertilize, setFertilize] = useState('');
  const [buttonRegisterFarmDisable, setButtonRegisterFarmDisable] =
    useState(true);
  const { user } = useAuth();

  const raster = () =>
    new TileLayer({
      preload: Infinity,
      source: new XYZ({
        url: `https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=${appConfig.servicesKey.mapTiler}`,
        maxZoom: 20
      }),
      name: 'TileMap'
    });

  const disableTileMap = (actived) => {
    refMap?.getLayers().forEach((layer) => {
      if (layer.get('name') === 'layerGroupMap') {
        layer.setVisible(actived);
      }
    });
    refMap?.getInteractions().forEach((interaction) => {
      interaction.setActive(actived);
    });
  };

  const maptiler = new LayerGroup({
    layers: [
      new TileLayer({
        source: new XYZ({
          url: `https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=${appConfig.servicesKey.mapTiler}`,
          crossOrigin: 'Anonymous',
          tileLoadFunction(tile, src) {
            tile.getImage().src = src;
          },
          maxZoom: 20
        })
      })
    ],
    name: 'layerGroupMap'
  });

  const view = new View({
    projection: 'EPSG:3857',
    center: mapCenter,
    zoom: 4
  });
  const scaleLine = new control.ScaleLine({
    bar: true,
    text: true,
    minWidth: 125,
    steps: 2
  });

  const newMap = (target, layer) =>
    new Map({
      target,
      layers: [layer],
      view,
      controls: control
        .defaults({
          zoom: true
        })
        .extend([scaleLine])
    });

  const getBboxOfGeometry = (geojson) => {
    const Source = new VectorSource({
      wrapX: true,
      features: new GeoJSON().readFeatures(geojson)
    });
    const bbox = Source.getExtent();
    return bbox;
  };

  const getHorizontalLengthOfGeometry = (geojson) => {
    const Source = new VectorSource({
      wrapX: true,
      features: new GeoJSON().readFeatures(geojson)
    });
    const bbox = Source.getExtent();
    const from = turf.point([bbox[0], bbox[1]]);
    const to = turf.point([bbox[2], bbox[1]]);
    return turf.distance(from, to, { units: 'meters' }).toFixed(2);
  };

  const zoomInGeometry = (farmGeoJson) => {
    const GeometrySource = new VectorSource({
      wrapX: true,
      features: new GeoJSON().readFeatures(farmGeoJson)
    });

    const GeometryLayer = new VectorLayer({
      source: GeometrySource
    });
    refMap.addLayer(GeometryLayer);
    refMap.getView().fit(GeometrySource.getExtent(), {
      size: refMap.getSize(),
      maxZoom: 18,
      padding: [10, 10, 10, 10]
    });
    refMap.removeLayer(GeometryLayer);
  };

  const removeAllInterections = () => {
    refMap.removeInteraction(draw);
    refMap.removeInteraction('drawer');
    refMap.removeInteraction(modify);
    refMap.removeInteraction(selecter);
  };

  const removeGeometries = () => {
    // setImportedShapefileName('');
    refMap.getLayers().forEach((layer) => {
      if (
        layer.get('name') === 'field' ||
        layer.get('name') === 'farm' ||
        layer.get('name') === 'walk' ||
        layer.get('name') === 'points' ||
        layer.get('name') === 'depth' ||
        layer.get('name') === 'subdivision' ||
        layer.get('name') === 'subdivisionColored' ||
        layer.get('name') === 'imported' ||
        layer.get('name') === 'farmSwipe'
      ) {
        geometryList.push(layer);
      }
    });
    geometryList.forEach((layer) => {
      refMap.removeLayer(layer);
    });
  };

  const removeGeometriesColoredSubdivisions = () => {
    // setImportedShapefileName('');
    refMap?.getLayers().forEach((layer) => {
      if (layer?.get('name') === 'subdivisionColored') {
        geometryList.push(layer);
      }
    });
    geometryList.forEach((layer) => {
      refMap.removeLayer(layer);
    });
  };

  const removeColoredRecommendationZone = () => {
    // setImportedShapefileName('');
    refMap?.getLayers().forEach((layer) => {
      if (
        layer?.get('name') === 'recommZoneColored' ||
        layer?.get('name') === 'recommZone'
      ) {
        geometryList.push(layer);
      }
    });
    geometryList.forEach((layer) => {
      refMap.removeLayer(layer);
    });
  };

  const removeRecommendationZone = () => {
    refMap.getLayers().forEach((layer) => {
      if (layer.get('name') === 'recommZone') {
        geometryList.push(layer);
      }
    });
    geometryList.forEach((layer) => {
      refMap.removeLayer(layer);
    });
  };

  const removeGeometriesSwipe = () => {
    // setImportedShapefileName('');
    refMap.getLayers().forEach((layer) => {
      if (layer.get('name') === 'farmSwipe') {
        geometryList.push(layer);
      }
    });
    geometryList.forEach((layer) => {
      refMap.removeLayer(layer);
    });
  };

  const removeImportedGeometries = () => {
    // setImportedShapefileName('');
    refMap.getLayers().forEach((layer) => {
      if (layer.get('name') === 'imported') {
        geometryList.push(layer);
      }
    });
    geometryList.forEach((layer) => {
      refMap.removeLayer(layer);
    });
  };

  const removeSubdivisionGeometries = () => {
    refMap.getLayers().forEach((layer) => {
      if (
        layer.get('name') === 'subdivision' ||
        layer.get('name') === 'restrictLayer'
      ) {
        geometryList.push(layer);
      }
    });
    geometryList.forEach((layer) => {
      refMap.removeLayer(layer);
    });

    if (refMap1 !== undefined) {
      refMap1.getLayers().forEach((layer) => {
        if (
          layer.get('name') === 'subdivision' ||
          layer.get('name') === 'points' ||
          layer.get('name') === 'depth'
        ) {
          geometryList.push(layer);
        }
      });
      geometryList.forEach((layer) => {
        refMap1.removeLayer(layer);
      });
    }

    if (refMap2 !== undefined) {
      refMap2.getLayers().forEach((layer) => {
        if (
          layer.get('name') === 'subdivision' ||
          layer.get('name') === 'points' ||
          layer.get('name') === 'depth'
        ) {
          geometryList.push(layer);
        }
      });
      geometryList.forEach((layer) => {
        refMap2.removeLayer(layer);
      });
    }

    if (refMap3 !== undefined) {
      refMap3.getLayers().forEach((layer) => {
        if (
          layer.get('name') === 'subdivision' ||
          layer.get('name') === 'points' ||
          layer.get('name') === 'depth'
        ) {
          geometryList.push(layer);
        }
      });
      geometryList.forEach((layer) => {
        refMap3.removeLayer(layer);
      });
    }

    if (refMap4 !== undefined) {
      refMap4.getLayers().forEach((layer) => {
        if (
          layer.get('name') === 'subdivision' ||
          layer.get('name') === 'points' ||
          layer.get('name') === 'depth'
        ) {
          geometryList.push(layer);
        }
      });
      geometryList.forEach((layer) => {
        refMap4.removeLayer(layer);
      });
    }
  };

  const removeRestrictionLayers = () => {
    refMap.getLayers().forEach((layer) => {
      if (layer.get('name') === 'restrictLayer') {
        geometryList.push(layer);
      }
    });
    geometryList.forEach((layer) => {
      refMap.removeLayer(layer);
    });
  };

  const removePointGeometries = () => {
    refMap.getLayers().forEach((layer) => {
      if (layer.get('name') === 'points' || layer.get('name') === 'depth') {
        geometryList.push(layer);
      }
    });
    geometryList.forEach((layer) => {
      refMap.removeLayer(layer);
    });
  };

  const removeWMSLayer = () => {
    refMap?.getLayers().forEach((layer) => {
      if (layer.get('name') === 'WMS-LAYER') {
        geometryList.push(layer);
      }
    });
    geometryList.forEach((layer) => {
      refMap.removeLayer(layer);
    });
  };

  const removeFarmLayer = () => {
    refMap.getLayers().forEach((layer) => {
      if (layer.get('name') === 'farm') {
        geometryList.push(layer);
      }
    });
    geometryList.forEach((layer) => {
      refMap.removeLayer(layer);
    });

    if (refMap1 !== undefined) {
      refMap1.getLayers().forEach((layer) => {
        if (layer.get('name') === 'farm') {
          geometryList.push(layer);
        }
      });
      geometryList.forEach((layer) => {
        refMap1.removeLayer(layer);
      });
    }

    if (refMap2 !== undefined) {
      refMap2.getLayers().forEach((layer) => {
        if (layer.get('name') === 'farm') {
          geometryList.push(layer);
        }
      });
      geometryList.forEach((layer) => {
        refMap2.removeLayer(layer);
      });
    }

    if (refMap3 !== undefined) {
      refMap3.getLayers().forEach((layer) => {
        if (layer.get('name') === 'farm') {
          geometryList.push(layer);
        }
      });
      geometryList.forEach((layer) => {
        refMap3.removeLayer(layer);
      });
    }

    if (refMap4 !== undefined) {
      refMap4.getLayers().forEach((layer) => {
        if (layer.get('name') === 'farm') {
          geometryList.push(layer);
        }
      });
      geometryList.forEach((layer) => {
        refMap4.removeLayer(layer);
      });
    }
  };
  const removeFieldGeometries = () => {
    refMap.getLayers().forEach((layer) => {
      if (layer.get('name') === 'field') {
        geometryList.push(layer);
      }
    });
    geometryList.forEach((layer) => {
      refMap.removeLayer(layer);
    });

    if (refMap1 !== undefined) {
      refMap1.getLayers().forEach((layer) => {
        if (layer.get('name') === 'field') {
          geometryList.push(layer);
        }
      });
      geometryList.forEach((layer) => {
        refMap1.removeLayer(layer);
      });
    }

    if (refMap2 !== undefined) {
      refMap2.getLayers().forEach((layer) => {
        if (layer.get('name') === 'field') {
          geometryList.push(layer);
        }
      });
      geometryList.forEach((layer) => {
        refMap2.removeLayer(layer);
      });
    }

    if (refMap3 !== undefined) {
      refMap3.getLayers().forEach((layer) => {
        if (layer.get('name') === 'field') {
          geometryList.push(layer);
        }
      });
      geometryList.forEach((layer) => {
        refMap3.removeLayer(layer);
      });
    }

    if (refMap4 !== undefined) {
      refMap4.getLayers().forEach((layer) => {
        if (layer.get('name') === 'field') {
          geometryList.push(layer);
        }
      });
      geometryList.forEach((layer) => {
        refMap4.removeLayer(layer);
      });
    }
  };

  const removeMappingGeometries = () => {
    refMap.getLayers().forEach((layer) => {
      if (layer.get('name') === 'walk') {
        geometryList.push(layer);
      }
    });
    geometryList.forEach((layer) => {
      refMap.removeLayer(layer);
    });
  };
  const readLayers = () => {
    const layers = [];
    setTimeout(() => {
      refMap?.getLayers().forEach((layer) => {
        if (
          layer.get('name') === 'field' ||
          layer.get('name') === 'farm' ||
          layer.get('name') === 'points' ||
          layer.get('name') === 'walk' ||
          layer.get('name') === 'subdivision' ||
          layer.get('name') === 'imported' ||
          layer.get('name') === 'depth' ||
          layer.get('name') === 'recommZone'
        ) {
          layers.push({ [layer.values_.name]: layer.values_.visible });
        }
      });
      refMap1?.getLayers().forEach((layer) => {
        if (
          layer.get('name') === 'field' ||
          layer.get('name') === 'farm' ||
          layer.get('name') === 'points' ||
          layer.get('name') === 'walk' ||
          layer.get('name') === 'subdivision' ||
          layer.get('name') === 'imported' ||
          layer.get('name') === 'depth'
        ) {
          layers.push({ [layer.values_.name]: layer.values_.visible });
        }
      });
      refMap2?.getLayers().forEach((layer) => {
        if (
          layer.get('name') === 'field' ||
          layer.get('name') === 'farm' ||
          layer.get('name') === 'points' ||
          layer.get('name') === 'walk' ||
          layer.get('name') === 'subdivision' ||
          layer.get('name') === 'imported' ||
          layer.get('name') === 'depth'
        ) {
          layers.push({ [layer.values_.name]: layer.values_.visible });
        }
      });
      refMap3?.getLayers().forEach((layer) => {
        if (
          layer.get('name') === 'field' ||
          layer.get('name') === 'farm' ||
          layer.get('name') === 'points' ||
          layer.get('name') === 'walk' ||
          layer.get('name') === 'subdivision' ||
          layer.get('name') === 'imported' ||
          layer.get('name') === 'depth'
        ) {
          layers.push({ [layer.values_.name]: layer.values_.visible });
        }
      });
      refMap4?.getLayers().forEach((layer) => {
        if (
          layer.get('name') === 'field' ||
          layer.get('name') === 'farm' ||
          layer.get('name') === 'points' ||
          layer.get('name') === 'walk' ||
          layer.get('name') === 'subdivision' ||
          layer.get('name') === 'imported' ||
          layer.get('name') === 'depth'
        ) {
          layers.push({ [layer.values_.name]: layer.values_.visible });
        }
      });
      setActiveLayers(layers);
    }, 2000);
  };

  const hideLayer = (layerName) => {
    refMap.getLayers().forEach((layer) => {
      if (layer.get('name') === layerName) {
        layer.values_.visible === true
          ? layer.setVisible(false)
          : layer.setVisible(true);
      }
    });
  };
  const [swipeActived, setSwapActived] = useState(false);
  const activedSwipe = swipeActived;

  const addSwipe = (feature) => {
    const swipe = new Swipe();
    if (activedSwipe === false) {
      refMap.addControl(swipe);
      swipe.addLayer(maptiler, true);
      swipe.addLayer(maptiler);
      const SwapSource = new VectorSource({
        wrapX: true,
        features: new GeoJSON().readFeatures(feature),
        name: 'farmSwipe'
      });
      const SwapLayer = new VectorLayer({
        source: SwapSource,
        zIndex: 999,
        name: 'farmSwipe'
      });

      refMap.addLayer(SwapLayer);
      swipe.addLayer(SwapLayer, true);
      refMap.getLayers().forEach((layer) => {
        if (layer.get('name') === 'subdivisionColored') {
          geometryList.push(layer);
        }
      });
      geometryList.forEach((layer) => {
        swipe.addLayer(layer, false);
      });

      return setSwapActived(true);
    }
    if (activedSwipe) {
      refMap.getControls().forEach((c) => {
        if (c instanceof Swipe) {
          refMap.removeControl(c);
          removeGeometriesSwipe();
        }
      });
      setSwapActived(false);
    }
  };

  const removeSwipe = () => {
    refMap.getControls().forEach((c) => {
      if (c instanceof Swipe) {
        refMap.removeControl(c);
        removeGeometriesSwipe();
      }
    });
    setSwapActived(false);
  };

  // const setFarmStyle = (borderColor = '#5596A9') => {
  //   return new Style({
  //     stroke: new Stroke({
  //       color: borderColor,
  //       width: 3
  //     }),
  //     fill: new Fill({
  //       color: 'rgba(0, 0, 0, 0)'
  //     })
  //   });
  // };

  const [farmStyle, setFarmStyle] = useState('#5596A9');
  const farmColor = farmStyle;
  const renderFarm = (geojson, borderColor = '#5596A9', maxZoom = 16) => {
    removeFarmLayer();
    setFarm(geojson);
    removeWMSLayer();
    removeFieldGeometries();
    removeSubdivisionGeometries();
    removeGeometries();

    const FarmSource = new VectorSource({
      wrapX: true,
      features: new GeoJSON().readFeatures(geojson),
      name: 'farm'
    });

    const FarmLayer = new VectorLayer({
      source: FarmSource,
      name: 'farm',
      style: new Style({
        stroke: new Stroke({
          color: borderColor,
          width: 3
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 0, 0)'
        })
      })
    });

    refMap.addLayer(FarmLayer);
    refMap.getView().fit(FarmSource.getExtent(), {
      size: refMap.getSize(),
      maxZoom
    });

    const FarmSource1 = new VectorSource({
      wrapX: true,
      features: new GeoJSON().readFeatures(geojson),
      name: 'farm'
    });

    const FarmLayer1 = new VectorLayer({
      source: FarmSource1,
      name: 'farm'
    });

    if (refMap1 !== undefined) {
      refMap1.addLayer(FarmLayer1);
      refMap1.getView().fit(FarmSource1.getExtent(), {
        size: refMap1.getSize(),
        maxZoom: 16
      });
      refMap1.removeLayer(FarmLayer);
    }

    const FarmSource2 = new VectorSource({
      wrapX: true,
      features: new GeoJSON().readFeatures(geojson),
      name: 'farm'
    });
    const FarmLayer2 = new VectorLayer({
      source: FarmSource2,
      name: 'farm'
    });
    if (refMap2 !== undefined) {
      refMap2.addLayer(FarmLayer2);
      refMap2.getView().fit(FarmSource2.getExtent(), {
        size: refMap2.getSize(),
        maxZoom: 16
      });
    }

    const FarmSource3 = new VectorSource({
      wrapX: true,
      features: new GeoJSON().readFeatures(geojson),
      name: 'farm'
    });
    const FarmLayer3 = new VectorLayer({
      source: FarmSource3,
      name: 'farm'
    });
    if (refMap3 !== undefined) {
      refMap3.addLayer(FarmLayer3);
      refMap3.getView().fit(FarmSource3.getExtent(), {
        size: refMap3.getSize(),
        maxZoom: 16
      });
    }

    const FarmSource4 = new VectorSource({
      wrapX: true,
      features: new GeoJSON().readFeatures(geojson),
      name: 'farm'
    });
    const FarmLayer4 = new VectorLayer({
      source: FarmSource3,
      name: 'farm'
    });
    if (refMap4 !== undefined) {
      refMap4.addLayer(FarmLayer4);
      refMap4.getView().fit(FarmSource4.getExtent(), {
        size: refMap4.getSize(),
        maxZoom: 16
      });
    }
  };

  const zoomField = (geojson) => {
    const FieldSource = new VectorSource({
      wrapX: true,
      features: new GeoJSON().readFeatures(geojson),
      name: 'field'
    });
    const FieldLayer = new VectorLayer({
      source: FieldSource,
      name: 'field'
    });

    refMap.addLayer(FieldLayer);
    refMap.getView().fit(FieldSource.getExtent(), {
      size: refMap.getSize(),
      maxZoom: 20
    });

    refMap.removeLayer(FieldLayer);
  };

  const viewMapping = (geojson) => {
    const mapping = geojson;

    farms.map((value) => {
      if (
        value.features[0].properties.farm_id ===
        mapping.features[0].properties.farm_id
      ) {
        return renderFarm(value);
      }
      return null;
    });
    const MappingSource = new VectorSource({
      wrapX: true,
      features: new GeoJSON().readFeatures(mapping),
      name: 'walk'
    });
    const MappingLayer = new VectorLayer({
      source: MappingSource,
      zIndex: 4,
      name: 'walk',
      style: new Style({
        stroke: new Stroke({
          color: 'yellow',
          width: 2
        })
      })
    });

    refMap.addLayer(MappingLayer);
    refMap.getView().fit(MappingSource.getExtent(), {
      size: refMap.getSize(),
      maxZoom: 13
    });
  };

  const renderShapefileOfCard = (geojson) => {
    removeImportedGeometries();
    setImportedShapefileName('Vizualização de Shapefile');
    const ImportedSource = new VectorSource({
      wrapX: true,
      features: new GeoJSON().readFeatures(geojson),
      name: 'imported'
    });
    const ImportedLayer = new VectorLayer({
      source: ImportedSource,
      name: 'imported',
      style: new Style({
        stroke: new Stroke({
          color: '#d43aaa',
          width: 3
        })
      })
    });
    refMap.addLayer(ImportedLayer);
    refMap.getView().fit(ImportedSource.getExtent(), {
      size: refMap.getSize(),
      maxZoom: 13
    });
    readLayers();
  };
  const renderMapping = (geojson) => {
    const mapping = geojson;

    const MappingSource = new VectorSource({
      wrapX: true,
      features: new GeoJSON().readFeatures(mapping),
      name: 'walk'
    });
    const MappingLayer = new VectorLayer({
      source: MappingSource,
      name: 'walk',
      style: new Style({
        stroke: new Stroke({
          color: 'yellow',
          width: 2
        })
      })
    });
    farms.forEach((v) => {
      if (
        turf.booleanIntersects(
          v.features[0].geometry,
          mapping.features[0].geometry
        )
      ) {
        refMap.addLayer(MappingLayer);
        refMap.getView().fit(MappingSource.getExtent(), {
          size: refMap.getSize(),
          maxZoom: 13
        });
      }
    });
  };

  const renderSubdivision = (geojson) => {
    const labelStyle = new Style({
      text: new Text({
        font: '12px Calibri,sans-serif',
        overflow: true,
        fill: new Fill({
          color: '#000'
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 3
        })
      })
    });

    const subdivisionStyle = new Style({
      stroke: new Stroke({
        color: '#fff',
        width: 2,
        lineDash: [4, 8]
      }),
      fill: new Fill({
        color: 'rgba(255,255,255,0.1)'
      })
    });

    const style = [subdivisionStyle, labelStyle];

    const features = new GeoJSON().readFeatures(geojson);
    const subdivisionName = geojson.features[0].properties.description;

    const subdivionsSource = new VectorSource({
      wrapX: true,
      features,
      name: 'subdivision'
    });

    const subdivisionLayer = new VectorLayer({
      source: subdivionsSource,
      zIndex: 3,
      style: () => {
        labelStyle.getText().setText(subdivisionName);
        return style;
      },
      declutter: true,
      name: 'subdivision'
    });
    refMap.addLayer(subdivisionLayer);
    refMap.getView().fit(subdivionsSource.getExtent(), {
      size: refMap.getSize(),
      maxZoom: 16
    });
  };

  const renderCollectionPoints = (geojson, borderColor = '#5596A9') => {
    if (geojson.length === 0) return;

    const myStyle = new Style({
      image: new Circle({
        radius: 2,
        fill: new Fill({ color: borderColor }),
        stroke: new Stroke({
          color: borderColor,
          width: 2
        })
      })
    });
    geojson.forEach((collectionPoints) => {
      const features = new GeoJSON().readFeatures(collectionPoints);

      if (
        collectionPoints.features[0].properties.type !== 'REFERENCIA_COLETA'
      ) {
        return;
      }

      const pointSource = new VectorSource({
        features,
        name: 'points'
      });
      const pointLayer = new VectorLayer({
        source: pointSource,
        name: 'points',
        style: myStyle
      });

      refMap.addLayer(pointLayer);
    });
  };

  const renderPoint = (geojson) => {
    if (geojson.length === 0) return;

    const features = new GeoJSON().readFeatures(geojson);

    const pointSource = new VectorSource({
      features,
      name: 'points'
    });
    const pointLayer = new VectorLayer({
      source: pointSource,
      name: 'points'
    });

    refMap.addLayer(pointLayer);
    refMap.getView().fit(pointSource.getExtent(), {
      size: refMap.getSize(),
      maxZoom: 16
    });
  };

  // funcao para renderizar pontos de profundidade centro
  const renderDepthPointFromShapeFile = (geojson) => {
    geojson.forEach((collectionPoints) => {
      const features = new GeoJSON().readFeatures(collectionPoints);
      const pointSource = new VectorSource({
        features,
        name: 'points'
      });
      const pointLayer = new VectorLayer({
        source: pointSource,
        name: 'points'
      });

      refMap.addLayer(pointLayer);
    });
  };

  const renderSubdivisionFromShapefile = (geojson) => {
    try {
      setSubdivision(geojson);

      const labelStyle = new Style({
        text: new Text({
          font: '12px Calibri,sans-serif',
          overflow: true,
          fill: new Fill({
            color: '#000'
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 3
          })
        })
      });

      const subdivisionStyle = new Style({
        stroke: new Stroke({
          color: '#fff',
          width: 2,
          lineDash: [4, 8]
        }),
        fill: new Fill({
          color: 'rgba(255,255,255,0.1)'
        })
      });

      const style = [subdivisionStyle, labelStyle];

      const subSource = new VectorSource({
        wrapX: true,
        features: new GeoJSON().readFeatures(geojson),
        name: 'subdivision'
      });
      const subLayer = new VectorLayer({
        source: subSource,
        name: 'subdivision'
      });

      const intersection = intersect(
        geojson.features[0].geometry,
        farm.features[0].geometry
      );

      setSubdivision(intersection);
      setDrawendSubdivision(true);
      let diff = null;
      const subdivisionsList = [];
      subdivisionsList.forEach((eSub) => {
        if (
          turf.booleanOverlap(
            geojson.features[0].geometry,
            eSub.features[0].geometry
          )
        ) {
          subdivisionsList.push(eSub.features[0].geometry);
        }
      });

      if (subdivisionsList.length > 0) {
        const union = turf.union(subdivisionsList[0], subdivisionsList[1]);
        diff = turf.difference(intersection, union);
        setSubdivision(diff);
      } else {
        diff = intersection;
        setSubdivision(diff);
      }

      const intersectGeometrySource = new VectorSource({
        features: new GeoJSON().readFeatures(diff),
        name: 'subdivision'
      });
      const intersectGeometryLayer = new VectorLayer({
        source: intersectGeometrySource,
        name: 'subdivision',
        style: () => {
          labelStyle
            .getText()
            .setText(geojson.features[0].properties.description);
          return style;
        }
      });
      refMap.addLayer(intersectGeometryLayer);
      refMap.removeLayer(subLayer);
    } catch (error) {
      console.error(error);
    }
  };

  const renderFieldFromShapefile = (geojson) => {
    try {
      setField(geojson);

      const labelStyle = new Style({
        text: new Text({
          font: '12px Calibri,sans-serif',
          overflow: true,
          fill: new Fill({
            color: '#000'
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 3
          })
        })
      });

      const fieldStyle = new Style({
        stroke: new Stroke({
          color: 'red',
          width: 1
        }),
        fill: new Fill({
          color: 'rgba(255,255,0,0.1)'
        })
      });
      const style = [fieldStyle, labelStyle];

      const FieldSource = new VectorSource({
        wrapX: true,
        features: new GeoJSON().readFeatures(geojson),
        name: 'field'
      });
      const FieldLayer = new VectorLayer({
        source: FieldSource,
        name: 'field'
      });

      const intersection = intersect(
        geojson.features[0].geometry,
        farm.features[0].geometry
      );

      setField(intersection);
      setDrawendField(true);
      let diff = null;
      const fieldsList = [];
      listFields.forEach((eField) => {
        if (
          turf.booleanOverlap(
            geojson.features[0].geometry,
            eField.features[0].geometry
          )
        ) {
          fieldsList.push(eField.features[0].geometry);
        }
      });

      if (fieldsList.length > 0) {
        const union = turf.union(fieldsList[0], fieldsList[1]);
        diff = turf.difference(intersection, union);
        setField(diff);
      } else {
        diff = intersection;
        setField(diff);
      }

      const intersectGeometrySource = new VectorSource({
        features: new GeoJSON().readFeatures(diff),
        name: 'field'
      });
      const intersectGeometryLayer = new VectorLayer({
        source: intersectGeometrySource,
        name: 'field',
        style: () => {
          labelStyle
            .getText()
            .setText(geojson?.features[0]?.properties?.field_name);
          return style;
        }
      });
      refMap.addLayer(intersectGeometryLayer);
      refMap.removeLayer(FieldLayer);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const modifyMappingField = (mapping) => {
    const features = new GeoJSON().readFeatures(mapping);
    const MappingSource = new VectorSource({
      wrapX: true,
      features,
      name: 'walk'
    });
    const MappingLayer = new VectorLayer({
      source: MappingSource,
      name: 'walk',
      style: new Style({
        stroke: new Stroke({
          color: 'yellow',
          width: 2
        })
      })
    });

    refMap.addLayer(MappingLayer);
    refMap.getView().fit(MappingSource.getExtent(), {
      size: refMap.getSize(),
      maxZoom: 13
    });
    const modifyer = new Modify({
      source: MappingSource
    });
    refMap.addInteraction(modifyer);
    setModify(modifyer);
    setEditedMapping(mapping);
    modifyer.on('modifystart', () => {
      setDrawing(true);
    });
    modifyer.on('modifyend', (drawned) => {
      const modifiedGeoJson = new GeoJSON();
      const modifiedGeom = drawned.features.getArray()[0];
      const geojsonReturn = JSON.parse(
        modifiedGeoJson.writeFeatures([modifiedGeom])
      );
      setEditedMapping(geojsonReturn);
    });
  };

  const renderFields = (geoJsonFields, borderColor = 'red') => {
    if (geoJsonFields.length === 0) return;

    removeFieldGeometries();

    const labelStyle = new Style({
      text: new Text({
        font: '12px Calibri,sans-serif',
        overflow: true,
        fill: new Fill({
          color: '#000'
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 3
        })
      })
    });

    const fieldStyle = new Style({
      stroke: new Stroke({
        color: borderColor,
        width: 3
      }),
      fill: new Fill({
        color: 'rgba(255,255,0,0)'
      })
    });
    const style = [fieldStyle, labelStyle];

    geoJsonFields.forEach((geoJsonField) => {
      const features = new GeoJSON().readFeatures(geoJsonField);
      const fieldName = geoJsonField.features[0].properties.field_name;

      const fieldSource = new VectorSource({
        wrapX: true,
        features,
        name: 'field'
      });

      const fieldLayer = new VectorLayer({
        source: fieldSource,
        zIndex: 3,
        style: () => {
          labelStyle.getText().setText(fieldName);
          return style;
        },
        declutter: true,
        name: 'field'
      });
      refMap.addLayer(fieldLayer);

      if (refMap1 !== undefined) {
        const fieldSource1 = new VectorSource({
          wrapX: true,
          features,
          name: 'field'
        });

        const fieldLayer1 = new VectorLayer({
          source: fieldSource1,
          zIndex: 3,
          style: () => {
            labelStyle.getText().setText(fieldName);
            return style;
          },
          declutter: true,
          name: 'field'
        });
        refMap1.addLayer(fieldLayer1);
      }

      if (refMap2 !== undefined) {
        const fieldSource2 = new VectorSource({
          wrapX: true,
          features,
          name: 'field'
        });

        const fieldLayer2 = new VectorLayer({
          source: fieldSource2,
          zIndex: 3,
          style: () => {
            labelStyle.getText().setText(fieldName);
            return style;
          },
          declutter: true,
          name: 'field'
        });
        refMap2.addLayer(fieldLayer2);
      }

      if (refMap3 !== undefined) {
        const fieldSource3 = new VectorSource({
          wrapX: true,
          features,
          name: 'field'
        });

        const fieldLayer3 = new VectorLayer({
          source: fieldSource3,
          zIndex: 3,
          style: () => {
            labelStyle.getText().setText(fieldName);
            return style;
          },
          declutter: true,
          name: 'field'
        });
        refMap3.addLayer(fieldLayer3);
      }

      if (refMap4 !== undefined) {
        const fieldSource4 = new VectorSource({
          wrapX: true,
          features,
          name: 'field'
        });

        const fieldLayer4 = new VectorLayer({
          source: fieldSource4,
          zIndex: 3,
          style: () => {
            labelStyle.getText().setText(fieldName);
            return style;
          },
          declutter: true,
          name: 'field'
        });
        refMap4.addLayer(fieldLayer4);
      }
    });
    readLayers();
  };
  const [croquis, setCroquis] = useState([]);

  const renderFieldsNoLabel = (geoJsonFields, borderColor = 'red') => {
    if (geoJsonFields.length === 0) return;

    removeFieldGeometries();

    const fieldStyle = new Style({
      stroke: new Stroke({
        color: borderColor,
        width: 3
      }),
      fill: new Fill({
        color: 'rgba(255,255,0,0)'
      })
    });
    const style = [fieldStyle];

    geoJsonFields.forEach((geoJsonField) => {
      const features = new GeoJSON().readFeatures(geoJsonField);

      const fieldSource = new VectorSource({
        wrapX: true,
        features,
        name: 'field'
      });

      const fieldLayer = new VectorLayer({
        source: fieldSource,
        zIndex: 3,
        style: () => {
          return style;
        },
        name: 'field'
      });
      refMap.addLayer(fieldLayer);

      if (refMap1 !== undefined) {
        const fieldSource1 = new VectorSource({
          wrapX: true,
          features,
          name: 'field'
        });

        const fieldLayer1 = new VectorLayer({
          source: fieldSource1,
          zIndex: 3,
          style: () => {
            return style;
          },
          declutter: true,
          name: 'field'
        });
        refMap1.addLayer(fieldLayer1);
      }

      if (refMap2 !== undefined) {
        const fieldSource2 = new VectorSource({
          wrapX: true,
          features,
          name: 'field'
        });

        const fieldLayer2 = new VectorLayer({
          source: fieldSource2,
          zIndex: 3,
          style: () => {
            return style;
          },
          declutter: true,
          name: 'field'
        });
        refMap2.addLayer(fieldLayer2);
      }

      if (refMap3 !== undefined) {
        const fieldSource3 = new VectorSource({
          wrapX: true,
          features,
          name: 'field'
        });

        const fieldLayer3 = new VectorLayer({
          source: fieldSource3,
          zIndex: 3,
          style: () => {
            return style;
          },
          declutter: true,
          name: 'field'
        });
        refMap3.addLayer(fieldLayer3);
      }

      if (refMap4 !== undefined) {
        const fieldSource4 = new VectorSource({
          wrapX: true,
          features,
          name: 'field'
        });

        const fieldLayer4 = new VectorLayer({
          source: fieldSource4,
          zIndex: 3,
          style: () => {
            return style;
          },
          declutter: true,
          name: 'field'
        });
        refMap4.addLayer(fieldLayer4);
      }
    });
    readLayers();
  };

  const renderDepthPoints = (geojson) => {
    removePointGeometries();
    if (geojson.length === 0) return;

    // removePointGeometries();
    // const features = geojson.map((points) => {
    //   return new GeoJSON().readFeatures(points)[0];
    // });

    geojson.forEach((depthPoint) => {
      const features = new GeoJSON().readFeatures(depthPoint);

      if (depthPoint.features[0].properties.type !== 'PROFUNDIDADE') {
        return;
      }

      const image = depthImages.filter(
        (value) =>
          `'${value.depth}'` === `'${depthPoint.features[0].properties.depth}'`
      )[0];

      const depthSource = new VectorSource({
        wrapX: true,
        features,
        name: 'depth'
      });

      const depthLayer = new VectorLayer({
        source: depthSource,
        zIndex: 3,
        name: 'depth',
        style: new Style({
          image: new Icon({
            anchor: [0.5, 12],
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
            src: image.image
          })
        })
      });

      refMap.addLayer(depthLayer);
    });
    readLayers();
  };

  const renderSubdivisions = (geoJsonSubdivisions, borderColor = '#FFF') => {
    removeSubdivisionGeometries();
    if (geoJsonSubdivisions.length === 0) return;

    const labelStyle = new Style({
      text: new Text({
        font: '11px Calibri,sans-serif',
        zIndex: 50,
        overflow: true,
        fill: new Fill({
          color: '#000'
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 3
        })
      })
    });

    const subdivisionStyle = new Style({
      stroke: new Stroke({
        color: borderColor,
        width: 2,
        lineDash: [4, 8]
      }),
      fill: new Fill({
        color: 'rgba(255,255,255,0)'
      })
    });

    const style = [subdivisionStyle, labelStyle];
    geoJsonSubdivisions.forEach((geoJsonSubdivision) => {
      const features = new GeoJSON().readFeatures(geoJsonSubdivision);
      const subdivisionName =
        geoJsonSubdivision.features[0].properties.description;
      const subdivionsSource = new VectorSource({
        wrapX: true,
        features,
        name: 'subdivision'
      });

      const subdivisionLayer = new VectorLayer({
        source: subdivionsSource,
        zIndex: 3,
        style: () => {
          labelStyle.getText().setText(subdivisionName);
          return style;
        },
        // declutter: true,
        name: 'subdivision'
      });
      refMap.addLayer(subdivisionLayer);

      if (refMap1 !== undefined) {
        const subdivionsSource1 = new VectorSource({
          wrapX: true,
          features,
          name: 'subdivision'
        });

        const subdivisionLayer1 = new VectorLayer({
          source: subdivionsSource1,
          zIndex: 3,
          style: () => {
            labelStyle.getText().setText(subdivisionName);
            return style;
          },
          declutter: true,
          name: 'subdivision'
        });
        refMap1.addLayer(subdivisionLayer1);
      }

      if (refMap2 !== undefined) {
        const subdivionsSource2 = new VectorSource({
          wrapX: true,
          features,
          name: 'subdivision'
        });

        const subdivisionLayer2 = new VectorLayer({
          source: subdivionsSource2,
          zIndex: 3,
          style: () => {
            labelStyle.getText().setText(subdivisionName);
            return style;
          },
          declutter: true,
          name: 'subdivision'
        });
        refMap2.addLayer(subdivisionLayer2);
      }

      if (refMap3 !== undefined) {
        const subdivionsSource3 = new VectorSource({
          wrapX: true,
          features,
          name: 'subdivision'
        });

        const subdivisionLayer3 = new VectorLayer({
          source: subdivionsSource3,
          zIndex: 3,
          style: () => {
            labelStyle.getText().setText(subdivisionName);
            return style;
          },
          declutter: true,
          name: 'subdivision'
        });
        refMap3.addLayer(subdivisionLayer3);
      }

      if (refMap4 !== undefined) {
        const subdivionsSource4 = new VectorSource({
          wrapX: true,
          features,
          name: 'subdivision'
        });

        const subdivisionLayer4 = new VectorLayer({
          source: subdivionsSource4,
          zIndex: 3,
          style: () => {
            labelStyle.getText().setText(subdivisionName);
            return style;
          },
          declutter: true,
          name: 'subdivision'
        });
        refMap4.addLayer(subdivisionLayer4);
      }
    });

    readLayers();
  };

  const renderSubdivisionsNoLabel = (
    geoJsonSubdivisions,
    borderColor = '#FFF'
  ) => {
    removeSubdivisionGeometries();
    if (geoJsonSubdivisions.length === 0) return;

    const subdivisionStyle = new Style({
      stroke: new Stroke({
        color: borderColor,
        width: 2,
        lineDash: [4, 8]
      }),
      fill: new Fill({
        color: 'rgba(255,255,255,0)'
      })
    });

    const style = [subdivisionStyle];
    geoJsonSubdivisions.forEach((geoJsonSubdivision) => {
      const features = new GeoJSON().readFeatures(geoJsonSubdivision);
      const subdivisionName =
        geoJsonSubdivision.features[0].properties.description;
      const subdivionsSource = new VectorSource({
        wrapX: true,
        features,
        name: 'subdivision'
      });

      const subdivisionLayer = new VectorLayer({
        source: subdivionsSource,
        zIndex: 3,
        style: () => {
          return style;
        },
        declutter: true,
        name: 'subdivision'
      });
      refMap.addLayer(subdivisionLayer);

      if (refMap1 !== undefined) {
        const subdivionsSource1 = new VectorSource({
          wrapX: true,
          features,
          name: 'subdivision'
        });

        const subdivisionLayer1 = new VectorLayer({
          source: subdivionsSource1,
          zIndex: 3,
          style: () => {
            return style;
          },
          declutter: true,
          name: 'subdivision'
        });
        refMap1.addLayer(subdivisionLayer1);
      }

      if (refMap2 !== undefined) {
        const subdivionsSource2 = new VectorSource({
          wrapX: true,
          features,
          name: 'subdivision'
        });

        const subdivisionLayer2 = new VectorLayer({
          source: subdivionsSource2,
          zIndex: 3,
          style: () => {
            return style;
          },
          declutter: true,
          name: 'subdivision'
        });
        refMap2.addLayer(subdivisionLayer2);
      }

      if (refMap3 !== undefined) {
        const subdivionsSource3 = new VectorSource({
          wrapX: true,
          features,
          name: 'subdivision'
        });

        const subdivisionLayer3 = new VectorLayer({
          source: subdivionsSource3,
          zIndex: 3,
          style: () => {
            return style;
          },
          declutter: true,
          name: 'subdivision'
        });
        refMap3.addLayer(subdivisionLayer3);
      }

      if (refMap4 !== undefined) {
        const subdivionsSource4 = new VectorSource({
          wrapX: true,
          features,
          name: 'subdivision'
        });

        const subdivisionLayer4 = new VectorLayer({
          source: subdivionsSource4,
          zIndex: 3,
          style: () => {
            return style;
          },
          declutter: true,
          name: 'subdivision'
        });
        refMap4.addLayer(subdivisionLayer4);
      }
    });

    readLayers();
  };

  const renderRecommendationZones = (geojson) => {
    if (geojson.length === 0) return;

    const features = geojson.forEach((geoJson) => {
      const feature = new GeoJSON().readFeature(geoJson.features[0]);

      const recommendationSource = new VectorSource({
        wrapX: true,
        features: [feature],
        name: 'recommZone',
        recommendationId: geoJson.features[0].properties.recommendation_zone_id
      });

      const recommendationLayer = new VectorLayer({
        source: recommendationSource,
        zIndex: 3,
        style: new Style({
          stroke: new Stroke({
            color: '#00F100',
            width: 3
          }),
          fill: new Fill({
            color: 'rgba(226,228,98, 0.6)'
          })
        }),
        name: 'recommZone',
        recommendationId: geoJson.features[0].properties.recommendation_zone_id
      });

      refMap.addLayer(recommendationLayer);
    });

    // const recommendationSource = new VectorSource({
    //   wrapX: true,
    //   features,
    //   name: 'recommZone'
    // });

    // const recommendationLayer = new VectorLayer({
    //   source: recommendationSource,
    //   zIndex: 3,
    //   style: new Style({
    //     stroke: new Stroke({
    //       color: '#85be37',
    //       width: 3
    //     }),
    //     fill: new Fill({
    //       color: 'rgba(11,156,49,0.4)'
    //     })
    //   }),
    //   name: 'recommZone'
    // });
    // refMap.addLayer(recommendationLayer);

    // geojson.forEach((geo) => {
    //   const feature = new GeoJSON().readFeature(geo.features[0]);

    //   const recommendationSource = new VectorSource({
    //     wrapX: true,
    //     feature,
    //     name: 'recommZone'
    //   });

    //   const recommendationLayer = new VectorLayer({
    //     source: recommendationSource,
    //     zIndex: 3,
    //     style: new Style({
    //       stroke: new Stroke({
    //         color: '#85be37',
    //         width: 3
    //       }),
    //       fill: new Fill({
    //         color: 'rgba(11,156,49,0.4)'
    //       })
    //     }),
    //     name: 'recommZone'
    //   });
    //   refMap.addLayer(recommendationLayer);
    // });
    readLayers();
  };

  const renderRecommendationZoneWithColors = (
    geoJsonRecommendationZones,
    colorSchemas
  ) => {
    removeColoredRecommendationZone();
    removeRecommendationZone();
    if (geoJsonRecommendationZones.length === 0) return;

    geoJsonRecommendationZones.forEach((geoJsonRecommendationZone) => {
      const features = new GeoJSON().readFeatures(geoJsonRecommendationZone);
      const color = colorSchemas.filter(
        (value) =>
          value.recommendation_zone_id ===
          geoJsonRecommendationZone.features[0].properties
            .recommendation_zone_id
      )[0];
      const recommendationZoneColorSource = new VectorSource({
        wrapX: true,
        features,
        name: 'recommZone'
      });
      const recommendationZoneColorLayer = new VectorLayer({
        source: recommendationZoneColorSource,
        zIndex: 44,
        style: new Style({
          stroke: new Stroke({
            color: '#85be37',
            width: 3
          }),
          fill: new Fill({
            opacity: 0.5,
            color:
              color?.color === null || color?.color === undefined
                ? 'rgba(255,255,255,0.1)'
                : transparentize(0.2, color?.color)
          })
        }),
        name: 'recommZone'
      });
      refMap.addLayer(recommendationZoneColorLayer);
    });
  };

  const renderRecommendationZoneWithColorsMapRef1 = (
    geoJsonRecommendationZones,
    colorSchemas
  ) => {
    removeColoredRecommendationZone();
    removeRecommendationZone();
    if (geoJsonRecommendationZones.length === 0) return;

    geoJsonRecommendationZones.forEach((geoJsonRecommendationZone) => {
      const features = new GeoJSON().readFeatures(geoJsonRecommendationZone);
      const color = colorSchemas.filter(
        (value) =>
          value.recommendation_zone_id ===
          geoJsonRecommendationZone.features[0].properties
            .recommendation_zone_id
      )[0];
      const recommendationZoneColorSource = new VectorSource({
        wrapX: true,
        features,
        name: 'recommZone'
      });
      const recommendationZoneColorLayer = new VectorLayer({
        source: recommendationZoneColorSource,
        zIndex: 44,
        style: new Style({
          stroke: new Stroke({
            color: '#85be37',
            width: 3
          }),
          fill: new Fill({
            opacity: 0.5,
            color:
              color?.color === null || color?.color === undefined
                ? 'rgba(255,255,255,0.1)'
                : transparentize(0.2, color?.color)
          })
        }),
        name: 'recommZone'
      });
      refMap1.addLayer(recommendationZoneColorLayer);
    });
  };

  const renderRecommendationZoneWithColorsMapRef2 = (
    geoJsonRecommendationZones,
    colorSchemas
  ) => {
    removeColoredRecommendationZone();
    removeRecommendationZone();
    if (geoJsonRecommendationZones.length === 0) return;

    geoJsonRecommendationZones.forEach((geoJsonRecommendationZone) => {
      const features = new GeoJSON().readFeatures(geoJsonRecommendationZone);
      const color = colorSchemas.filter(
        (value) =>
          value.recommendation_zone_id ===
          geoJsonRecommendationZone.features[0].properties
            .recommendation_zone_id
      )[0];
      const recommendationZoneColorSource = new VectorSource({
        wrapX: true,
        features,
        name: 'recommZone'
      });
      const recommendationZoneColorLayer = new VectorLayer({
        source: recommendationZoneColorSource,
        zIndex: 44,
        style: new Style({
          stroke: new Stroke({
            color: '#85be37',
            width: 3
          }),
          fill: new Fill({
            opacity: 0.5,
            color:
              color?.color === null || color?.color === undefined
                ? 'rgba(255,255,255,0.1)'
                : transparentize(0.2, color?.color)
          })
        }),
        name: 'recommZone'
      });
      refMap2.addLayer(recommendationZoneColorLayer);
    });
  };

  const renderRecommendationZoneWithColorsMapRef3 = (
    geoJsonRecommendationZones,
    colorSchemas
  ) => {
    removeColoredRecommendationZone();
    removeRecommendationZone();
    if (geoJsonRecommendationZones.length === 0) return;

    geoJsonRecommendationZones.forEach((geoJsonRecommendationZone) => {
      const features = new GeoJSON().readFeatures(geoJsonRecommendationZone);
      const color = colorSchemas.filter(
        (value) =>
          value.recommendation_zone_id ===
          geoJsonRecommendationZone.features[0].properties
            .recommendation_zone_id
      )[0];
      const recommendationZoneColorSource = new VectorSource({
        wrapX: true,
        features,
        name: 'recommZone'
      });
      const recommendationZoneColorLayer = new VectorLayer({
        source: recommendationZoneColorSource,
        zIndex: 44,
        style: new Style({
          stroke: new Stroke({
            color: '#85be37',
            width: 3
          }),
          fill: new Fill({
            opacity: 0.5,
            color:
              color?.color === null || color?.color === undefined
                ? 'rgba(255,255,255,0.1)'
                : transparentize(0.2, color?.color)
          })
        }),
        name: 'recommZone'
      });
      refMap3.addLayer(recommendationZoneColorLayer);
    });
  };

  const renderRecommendationZoneWithColorsMapRef4 = (
    geoJsonRecommendationZones,
    colorSchemas
  ) => {
    removeColoredRecommendationZone();
    removeRecommendationZone();
    if (geoJsonRecommendationZones.length === 0) return;

    geoJsonRecommendationZones.forEach((geoJsonRecommendationZone) => {
      const features = new GeoJSON().readFeatures(geoJsonRecommendationZone);
      const color = colorSchemas.filter(
        (value) =>
          value.recommendation_zone_id ===
          geoJsonRecommendationZone.features[0].properties
            .recommendation_zone_id
      )[0];
      const recommendationZoneColorSource = new VectorSource({
        wrapX: true,
        features,
        name: 'recommZone'
      });
      const recommendationZoneColorLayer = new VectorLayer({
        source: recommendationZoneColorSource,
        zIndex: 44,
        style: new Style({
          stroke: new Stroke({
            color: '#85be37',
            width: 3
          }),
          fill: new Fill({
            opacity: 0.5,
            color:
              color?.color === null || color?.color === undefined
                ? 'rgba(255,255,255,0.1)'
                : transparentize(0.2, color?.color)
          })
        }),
        name: 'recommZone'
      });
      refMap4.addLayer(recommendationZoneColorLayer);
    });
  };

  const renderSubdivisionsWithColors = (
    geoJsonSubdivisions,
    colorSchemas,
    borderColor = '#FFF'
  ) => {
    removeGeometriesColoredSubdivisions();
    if (geoJsonSubdivisions.length === 0) return;

    geoJsonSubdivisions.forEach((geoJsonSubdivision) => {
      const features = new GeoJSON().readFeatures(geoJsonSubdivision);
      const color = colorSchemas.find(
        (value) =>
          value.subdivision ===
          geoJsonSubdivision.features[0].properties.subdivision_id
      );

      const labelStyle = new Style({
        text: new Text({
          font: '11px Calibri,sans-serif',
          zIndex: 50,
          overflow: true,
          fill: new Fill({
            color: '#000'
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 2
          })
        })
      });

      const subdivisionStyle = new Style({
        stroke: new Stroke({
          color: borderColor,
          width: 2,
          lineDash: [4, 8]
        }),
        fill: new Fill({
          color:
            color?.hexaColor === null || color?.hexaColor === undefined
              ? 'rgba(255,255,255,0.0)'
              : color?.hexaColor
        })
      });

      const style = [subdivisionStyle, labelStyle];

      const subdivionColorSource = new VectorSource({
        wrapX: true,
        features,
        name: 'subdivisionColored'
      });
      const subdivisionColorLayer = new VectorLayer({
        source: subdivionColorSource,
        zIndex: 44,
        style: () => {
          labelStyle
            .getText()
            .setText(geoJsonSubdivision.features[0].properties.description);
          return style;
        },
        name: 'subdivisionColored'
      });
      refMap.addLayer(subdivisionColorLayer);
    });
  };

  const renderSubdivisionsWithColorsMapRef1 = (
    geoJsonSubdivisions,
    colorSchemas
  ) => {
    removeGeometriesColoredSubdivisions();
    if (geoJsonSubdivisions.length === 0) return;

    geoJsonSubdivisions.forEach((geoJsonSubdivision) => {
      const features = new GeoJSON().readFeatures(geoJsonSubdivision);
      const color = colorSchemas.filter(
        (value) =>
          value.subdivision ===
          geoJsonSubdivision.features[0].properties.subdivision_id
      )[0];
      const subdivionColorSource = new VectorSource({
        wrapX: true,
        features,
        name: 'subdivisionColored'
      });
      const subdivisionColorLayer = new VectorLayer({
        source: subdivionColorSource,
        zIndex: 44,
        style: new Style({
          stroke: new Stroke({
            color: '#fff',
            width: 2,
            lineDash: [4, 8]
          }),
          fill: new Fill({
            color:
              color?.hexaColor === null || color?.hexaColor === undefined
                ? 'rgba(255,255,255,0.1)'
                : color?.hexaColor
          })
        }),

        name: 'subdivisionColored'
      });
      refMap1.addLayer(subdivisionColorLayer);
    });
  };

  const renderSubdivisionsWithColorsMapRef2 = (
    geoJsonSubdivisions,
    colorSchemas
  ) => {
    removeGeometriesColoredSubdivisions();
    if (geoJsonSubdivisions.length === 0) return;

    geoJsonSubdivisions.forEach((geoJsonSubdivision) => {
      const features = new GeoJSON().readFeatures(geoJsonSubdivision);
      const color = colorSchemas.filter(
        (value) =>
          value.subdivision ===
          geoJsonSubdivision.features[0].properties.subdivision_id
      )[0];
      const subdivionColorSource = new VectorSource({
        wrapX: true,
        features,
        name: 'subdivisionColored'
      });
      const subdivisionColorLayer = new VectorLayer({
        source: subdivionColorSource,
        zIndex: 44,
        style: new Style({
          stroke: new Stroke({
            color: '#fff',
            width: 2,
            lineDash: [4, 8]
          }),
          fill: new Fill({
            color:
              color?.hexaColor === null || color?.hexaColor === undefined
                ? 'rgba(255,255,255,0.1)'
                : color?.hexaColor
          })
        }),

        name: 'subdivisionColored'
      });
      refMap2.addLayer(subdivisionColorLayer);
    });
  };

  const renderSubdivisionsWithColorsMapRef3 = (
    geoJsonSubdivisions,
    colorSchemas
  ) => {
    removeGeometriesColoredSubdivisions();
    if (geoJsonSubdivisions.length === 0) return;

    geoJsonSubdivisions.forEach((geoJsonSubdivision) => {
      const features = new GeoJSON().readFeatures(geoJsonSubdivision);
      const color = colorSchemas.filter(
        (value) =>
          value.subdivision ===
          geoJsonSubdivision.features[0].properties.subdivision_id
      )[0];
      const subdivionColorSource = new VectorSource({
        wrapX: true,
        features,
        name: 'subdivisionColored'
      });
      const subdivisionColorLayer = new VectorLayer({
        source: subdivionColorSource,
        zIndex: 44,
        style: new Style({
          stroke: new Stroke({
            color: '#fff',
            width: 2,
            lineDash: [4, 8]
          }),
          fill: new Fill({
            color:
              color?.hexaColor === null || color?.hexaColor === undefined
                ? 'rgba(255,255,255,0.1)'
                : color?.hexaColor
          })
        }),

        name: 'subdivisionColored'
      });
      refMap3.addLayer(subdivisionColorLayer);
    });
  };

  const renderSubdivisionsWithColorsMapRef4 = (
    geoJsonSubdivisions,
    colorSchemas
  ) => {
    removeGeometriesColoredSubdivisions();
    if (geoJsonSubdivisions.length === 0) return;

    geoJsonSubdivisions.forEach((geoJsonSubdivision) => {
      const features = new GeoJSON().readFeatures(geoJsonSubdivision);
      const color = colorSchemas.filter(
        (value) =>
          value.subdivision ===
          geoJsonSubdivision.features[0].properties.subdivision_id
      )[0];
      const subdivionColorSource = new VectorSource({
        wrapX: true,
        features,
        name: 'subdivisionColored'
      });
      const subdivisionColorLayer = new VectorLayer({
        source: subdivionColorSource,
        zIndex: 44,
        style: new Style({
          stroke: new Stroke({
            color: '#fff',
            width: 2,
            lineDash: [4, 8]
          }),
          fill: new Fill({
            color:
              color?.hexaColor === null || color?.hexaColor === undefined
                ? 'rgba(255,255,255,0.1)'
                : color?.hexaColor
          })
        }),

        name: 'subdivisionColored'
      });
      refMap4.addLayer(subdivisionColorLayer);
    });
  };

  const createFarm = () => {
    removeGeometries();

    const FarmSource = new VectorSource({
      wrapX: true,
      name: 'farm'
    });

    const FarmLayer = new VectorLayer({
      source: FarmSource,
      name: 'farm'
    });

    const drawer = new Draw({
      source: FarmSource,
      type: 'Polygon'
    });

    setDraw(drawer);

    refMap.addInteraction(drawer);
    refMap.addLayer(FarmLayer);

    drawer.on('drawend', (drawned) => {
      setButtonRegisterFarmDisable(false);
      refMap.removeInteraction(drawer);
      const geoJson = JSON.parse(geoJsonObj.writeFeatures([drawned.feature]));
      setFarm(geoJson);
    });
  };

  // calcular a area total de uma geometria para transformar em hectares
  // const calculateHa = (geojson) => {
  //   const geoMod = geojson;
  //   // const parseTo = parseCoordinatesToTest4326(geoMod);
  //   const poly = turf.polygon([geojson.features[0].geometry.coordinates[0]]);
  //   const area = turf.area(poly);
  //   const result = area / 10000;
  // };

  const createWalk = () => {
    const WalkSource = new VectorSource({
      wrapX: true,
      name: 'walk'
    });

    const WalkLayer = new VectorLayer({
      source: WalkSource,
      name: 'walk',
      style: new Style({
        stroke: new Stroke({
          color: 'yellow',
          width: 3
        })
      })
    });

    const drawer = new Draw({
      source: WalkSource,
      type: 'LineString'
    });

    setDraw(drawer);

    refMap.addInteraction(drawer);
    refMap.addLayer(WalkLayer);

    drawer.on('drawend', (drawned) => {
      refMap.removeInteraction(drawer);
      const geoJson = JSON.parse(geoJsonObj.writeFeatures([drawned.feature]));
      return geoJson;
    });
  };

  const createWalkSubdivision = (geoJsonSubdivision) => {
    removeSubdivisionGeometries();
    const restrictionSubdivisionSource = new VectorSource({
      features: new GeoJSON().readFeatures(geoJsonSubdivision)
    });

    const restrictionSubdivisionLayer = new VectorLayer({
      source: restrictionSubdivisionSource,
      zIndex: 999,
      style: new Style({
        stroke: new Stroke({
          color: '#fff',
          width: 2,
          lineDash: [4, 8]
        }),
        fill: new Fill({
          color: 'rgba(255,255,255,0.1)'
        })
      })
    });

    refMap.addLayer(restrictionSubdivisionLayer);
    const WalkSource = new VectorSource({
      wrapX: true,
      name: 'walk'
    });
    const WalkLayer = new VectorLayer({
      source: WalkSource,
      name: 'walk',
      zIndex: 998,
      style: new Style({
        stroke: new Stroke({
          color: 'yellow',
          width: 3
        })
      })
    });

    const drawer = new Draw({
      condition: (e) => {
        // const coords = e.coordinate;
        const features = refMap.getFeaturesAtPixel(e.pixel, {
          layerFilter(layer) {
            return layer === restrictionSubdivisionLayer;
          }
        });
        if (features != null && features.length > 0) {
          return true;
        }
        return false;
      },
      source: WalkSource,
      type: 'LineString'
    });

    setDraw(drawer);

    refMap.addInteraction(drawer);
    refMap.addLayer(WalkLayer);

    drawer.on('drawend', (drawned) => {
      refMap.removeInteraction(drawer);
      const geoJson = JSON.parse(geoJsonObj.writeFeatures([drawned.feature]));
      const fieldsIntersect = listFields
        .map((f) => {
          if (
            turf.booleanIntersects(
              f.features[0].geometry,
              geoJson.features[0].geometry
            )
          ) {
            return f.features[0].properties.field_id;
          }
          return '';
        })
        .filter((value) => value !== '' ?? value);
      geoJson.features[0].properties = {
        subdivision_id:
          geoJsonSubdivision.features[0].properties.subdivision_id,
        fieldList: fieldsIntersect
      };
      setWalkOfSubdivision(geoJson);
    });
  };

  let mappings = [];
  const resetMappings = () => (mappings = []);
  const createWalkSubdivisions = (subdivisions) => {
    const features = subdivisions.map((value) =>
      new GeoJSON().readFeature(value.features[0])
    );
    const subdivisionsSource = new VectorSource({
      features,
      name: 'restrictLayer'
    });

    const subdivisionsLayer = new VectorLayer({
      source: subdivisionsSource,
      style: new Style({
        stroke: new Stroke({
          color: '#fff',
          width: 2,
          lineDash: [4, 8]
        }),
        fill: new Fill({
          color: 'rgba(255,255,255,0.1)'
        })
      }),
      name: 'restrictLayer'
    });
    const select = new Select({
      condition: pointerMove,
      layers: [subdivisionsLayer]
    });
    setSelecter(select);
    refMap.addInteraction(select);
    refMap.addLayer(subdivisionsLayer);

    const featuresS = mappings.map((value) =>
      new GeoJSON().readFeature(value.features[0])
    );

    const WalkSource = new VectorSource({
      wrapX: true,
      features: featuresS,
      name: 'walk'
    });

    const WalkLayer = new VectorLayer({
      source: WalkSource,
      name: 'walk',
      zIndex: 998,
      style: new Style({
        stroke: new Stroke({
          color: 'yellow',
          width: 3
        })
      })
    });
    refMap.addLayer(WalkLayer);
    let restrictionSubdivisionLayer = null;

    const drawer = new Draw({
      condition: (ev) => {
        // const coords = e.coordinate;
        const feat = refMap.getFeaturesAtPixel(ev.pixel, {
          layerFilter(layer) {
            return layer === restrictionSubdivisionLayer;
          }
        });
        if (feat != null && feat.length > 0) {
          return true;
        }
        return false;
      },
      source: WalkSource,
      type: 'LineString',
      name: 'walk'
    });
    setDraw(drawer);
    let geoJsonSubdivision = {};
    drawer.on('drawend', (drawned) => {
      refMap.removeInteraction(drawer);
      subdivisionsLayer.setVisible(false);
      const geoJson = JSON.parse(geoJsonObj.writeFeatures([drawned.feature]));
      const fieldsIntersect = listFields
        .map((f) => {
          if (
            turf.booleanIntersects(
              f.features[0].geometry,
              geoJson.features[0].geometry
            )
          ) {
            return f.features[0].properties.field_id;
          }
          return '';
        })
        .filter((value) => value !== '' ?? value);
      geoJson.features[0].properties = {
        subdivision_id:
          geoJsonSubdivision.features[0].properties.subdivision_id,
        fieldList: fieldsIntersect
      };
      mappings.push(geoJson);
      setGeneratedMappingsSubdivisions(mappings);
      createWalkSubdivisions(subdivisions);
    });
    select.on('select', (e) => {
      if (e.selected.length < 1) return;

      const geoJson = JSON.parse(geoJsonObj.writeFeatures([e.selected[0]]));
      geoJsonSubdivision = geoJson;
      const restrictionSubdivisionSource = new VectorSource({
        features: new GeoJSON().readFeatures(geoJson)
      });
      restrictionSubdivisionLayer = new VectorLayer({
        source: restrictionSubdivisionSource,
        zIndex: 999,
        style: new Style({
          stroke: new Stroke({
            color: '#fff',
            width: 2,
            lineDash: [4, 8]
          }),
          fill: new Fill({
            color: 'rgba(255,255,255,0.1)'
          })
        })
      });
      refMap.addLayer(restrictionSubdivisionLayer);

      refMap.addInteraction(drawer);
    });
  };

  const createMapping = (geoJsonFarm) => {
    removeMappingGeometries();
    removeFarmLayer();
    const restrictionFarmSource = new VectorSource({
      features: new GeoJSON().readFeatures(geoJsonFarm),
      name: 'farm'
    });

    const restrictionFarmLayer = new VectorLayer({
      source: restrictionFarmSource,
      zIndex: 2,
      name: 'farm'
    });

    refMap.addLayer(restrictionFarmLayer);

    const MappingSource = new VectorSource({
      wrapX: true,
      name: 'points'
    });

    const MappingLayer = new VectorLayer({
      source: MappingSource,
      name: 'points',
      zIndex: 998
    });

    const drawer = new Draw({
      condition: (e) => {
        // const coords = e.coordinate;
        const features = refMap.getFeaturesAtPixel(e.pixel, {
          layerFilter(layer) {
            return layer === restrictionFarmLayer;
          }
        });
        if (features != null && features.length > 0) {
          return true;
        }
        return false;
      },
      source: MappingSource,
      type: 'LineString'
    });

    setDraw(drawer);

    refMap.addInteraction(drawer);
    refMap.addLayer(MappingLayer);

    drawer.on('drawend', (drawned) => {
      refMap.removeInteraction(drawer);
      const geoJson = JSON.parse(geoJsonObj.writeFeatures([drawned.feature]));
      const fieldsIntersect = listFields
        .map((f) => {
          if (
            turf.booleanIntersects(
              f.features[0].geometry,
              geoJson.features[0].geometry
            )
          ) {
            return f.features[0].properties.field_id;
          }
          return '';
        })
        .filter((value) => value !== '' ?? value);
      geoJson.features[0].properties = {
        fieldList: fieldsIntersect
      };
      setFarmMapping(geoJson);
    });
  };

  const addFields = () => {
    setDrawing(true);
    const FieldSource = new VectorSource({
      wrapX: true,
      name: 'field'
    });
    const FieldLayer = new VectorLayer({
      source: FieldSource,
      name: 'field'
    });
    const drawer = new Draw({
      source: FieldSource,
      type: 'Polygon'
    });
    setDraw(drawer);
    refMap.addInteraction(drawer);
    refMap.addLayer(FieldLayer);

    drawer.on('drawend', (drawned) => {
      const geoJson = JSON.parse(geoJsonObj.writeFeatures([drawned.feature]));
      const intersection = intersect(
        geoJson.features[0].geometry,
        farm.features[0].geometry
      );
      geoJson.features[0].geometry = intersection.geometry;
      // setField(intersection);
      setDrawendField(true);
      let diff = null;
      const fieldsList = [];
      listFields.forEach((eField) => {
        if (
          turf.booleanOverlap(
            geoJson.features[0].geometry,
            eField.features[0].geometry
          )
        ) {
          fieldsList.push(eField.features[0].geometry);
        }
      });
      let union = null;
      if (fieldsList.length > 0) {
        let first = true;
        for (let i = 0; i < fieldsList.length; i++) {
          if (first) {
            union = fieldsList[i];
            first = false;
          } else {
            union = turf.union(union, fieldsList[i]);
          }
        }
        diff = turf.difference(intersection, union);
        geoJson.features[0].geometry = diff.geometry;
      } else {
        diff = intersection;
        geoJson.features[0].geometry = diff.geometry;
      }

      setField(geoJson);

      const intersectGeometrySource = new VectorSource({
        features: new GeoJSON().readFeatures(diff),
        name: 'field'
      });
      const intersectGeometryLayer = new VectorLayer({
        source: intersectGeometrySource,
        name: 'field',
        style: new Style({
          stroke: new Stroke({
            color: 'red',
            width: 1
          }),
          fill: new Fill({
            color: 'rgba(255,255,0,0.1)'
          })
        })
      });
      refMap.addLayer(intersectGeometryLayer);
      refMap.removeLayer(FieldLayer);
      refMap.removeInteraction(drawer);
    });
  };

  const renderRecommendationZone = (geojson) => {
    removeRecommendationZone();
    const features = new GeoJSON().readFeatures(geojson);
    const recommendedZoneSource = new VectorSource({
      wrapX: true,
      features,
      name: 'recommZone'
    });
    const recommendedZoneLayer = new VectorLayer({
      source: recommendedZoneSource,
      name: 'recommZone',
      zIndex: 6000,
      style: new Style({
        stroke: new Stroke({
          color: '#85be37',
          width: 3
        }),
        fill: new Fill({
          color: 'rgba(11,156,49,0.4)'
        })
      })
    });
    refMap.addLayer(recommendedZoneLayer);
    // refMap.getView().fit(recommendedZoneSource.getExtent(), {
    //   size: refMap.getSize(),
    //   maxZoom: 11
    // });
  };

  const editRecommendationZone = (recommListSubdivisions) => {
    setGeomList([]);
    const geoList = [];
    refMap.getLayers().forEach((layer) => {
      if (
        layer.get('name') === 'recommZone' &&
        layer.get('recommendationId') ===
          recommListSubdivisions.features[0].properties.recommendation_zone_id
      ) {
        geoList.push(layer);
      }
    });
    geoList.forEach((layer) => {
      refMap.removeLayer(layer);
    });
    // const selectSubs = [];
    // listSubdivisions.forEach((ss) => {
    //   selectSubs.push(JSON.parse(new GeoJSON().writeFeatures([ss])));
    // });

    // let union = null;
    // for (let i = 0; i < listSubdivisions.length; i++) {
    //   if (union === null) {
    //     union = listSubdivisions[i].features[0].geometry;
    //   } else {
    //     union = turf.union(union, listSubdivisions[i].features[0].geometry);
    //   }
    // }

    // const reccomGeometrySource = new VectorSource({
    //   features: new GeoJSON().readFeatures(listSubdivisions),
    //   name: 'recommZone'
    // });
    // const reccomGeometryLayer = new VectorLayer({
    //   source: reccomGeometrySource,
    //   name: 'recommZone',
    //   zIndex: 2000,
    //   style: new Style({
    //     stroke: new Stroke({
    //       color: 'rgba(255,255,255,0.7)',
    //       width: 3
    //     }),
    //     fill: new Fill({
    //       color: 'rgba(255,255,255,0.7)'
    //     })
    //   })
    // });

    // refMap.addLayer(reccomGeometryLayer);

    // const subdivisionSelected = listSubdivisions.filter((value) =>
    //   recommListSubdivisions.features[0].properties.subdivisions_list.includes(
    //     value.features[0].properties.subdivision_id
    //   )
    // );

    const layersub = [];
    refMap.getLayers().forEach((layer) => {
      if (layer.get('name') === 'subdivision') {
        layersub.push(layer);
      }
    });

    const select = new Select({
      layers: layersub,
      addCondition: (e) => {
        const names = [];
        const highlightStyle = new Style({
          fill: new Fill({
            color: 'rgba(255,255,255,0.7)'
          }),
          stroke: new Stroke({
            color: '#3399CC',
            width: 3
          })
        });
        // subdivisionSelected.forEach((sub) => {
        //   const feature = new GeoJSON().readFeature(sub.features[0]);
        //   names.push(feature.getProperties().description);
        //   feature.setStyle(highlightStyle);
        //   geomList.push(feature);
        //   // return feature;
        // });
        setSelectedSubdivisionsForReccomendationZone([]);

        e.map.forEachFeatureAtPixel(e.pixel, (feature, layer) => {
          if (!layer) return;
          if (layer.getProperties().name === 'subdivision') {
            const selIndex = geomList.indexOf(feature);
            if (selIndex < 0) {
              feature.setStyle(highlightStyle);
              geomList.push(feature);
              names.push(feature.getProperties().description);
              setSelectedSubdivisionsForReccomendationZone([...geomList]);
            } else {
              geomList.splice(selIndex, 1);
              feature.setStyle(undefined);
              setSelectedSubdivisionsForReccomendationZone([...geomList]);
            }
          }
        });
      }
    });
    setSelecter(select);
    refMap.addInteraction(select);
  };

  const createRecommendationZoneSelect = () => {
    setGeomList([]);
    selectedSubdivisionsForReccomendationZone.splice();
    let selectedSubdivisions = [];
    const layersub = [];
    refMap.getLayers().forEach((layer) => {
      if (layer.get('name') === 'subdivision') {
        layersub.push(layer);
      }
    });
    const select = new Select({
      layers: layersub,
      addCondition: (e) => {
        const names = [];
        const highlightStyle = new Style({
          fill: new Fill({
            color: 'rgba(255,255,255,0.7)'
          }),
          stroke: new Stroke({
            color: '#3399CC',
            width: 3
          })
        });

        e.map.forEachFeatureAtPixel(e.pixel, (feature, layer) => {
          if (!layer) return;
          if (layer.getProperties().name === 'subdivision') {
            const selIndex = geomList.indexOf(feature);
            if (selIndex < 0) {
              feature.setStyle(highlightStyle);
              geomList.push(feature);
              names.push(feature.getProperties().description);
              setSelectedSubdivisionsForReccomendationZone(geomList);
            } else {
              geomList.splice(selIndex, 10);
              feature.setStyle(undefined);
              setSelectedSubdivisionsForReccomendationZone(geomList);
            }
          }
        });
      }
    });
    setSelecter(select);
    refMap.addInteraction(select);
  };

  const parseCoordinates = (geoJson) => {
    const coordinates = [];
    geoJson.features[0].geometry.coordinates[0].forEach((group) => {
      group.forEach((coordinateGroup) => coordinates.push(coordinateGroup));
    });
    return {
      ...geoJson,
      features: [
        {
          ...geoJson.features[0],
          geometry: {
            ...geoJson.features[0].geometry,
            coordinates: [[coordinates]]
          }
        }
      ]
    };
  };

  const finalizeCreateRecommendationZoneSelect = () => {
    removeAllInterections();
    const selectSubs = [];
    selectedSubdivisionsForReccomendationZone.forEach((ss) => {
      selectSubs.push(JSON.parse(new GeoJSON().writeFeatures([ss])));
    });

    let union = null;
    for (let i = 0; i < selectSubs.length; i++) {
      if (union === null) {
        union = selectSubs[i].features[0].geometry;
      } else {
        union = turf.union(union, selectSubs[i].features[0].geometry);
      }
    }
    // setRecommZoneDrawed(union);

    const featureUnion = new GeoJSON().readFeature(union);
    const geoJsonFeature = JSON.parse(
      new GeoJSON().writeFeatures([featureUnion])
    );
    geoJsonFeature.properties = {
      subdivisions_list: selectSubs.map(
        (v) => v.features[0].properties.subdivision_id
      )
    };

    recommZoneDrawed.push(geoJsonFeature);

    const reccomGeometrySource = new VectorSource({
      features: new GeoJSON().readFeatures(union),
      name: 'recommZone'
    });
    const reccomGeometryLayer = new VectorLayer({
      source: reccomGeometrySource,
      name: 'recommZone',
      zIndex: 2000,
      style: new Style({
        stroke: new Stroke({
          color: '#85be37',
          width: 3
        }),
        fill: new Fill({
          color: 'rgba(11,156,49,0.4)'
        })
      })
    });
    refMap.addLayer(reccomGeometryLayer);
    refMap.removeInteraction(selecter);
  };

  const finalizeEditRecommendationZone = () => {
    removeAllInterections();
    let selectSubs = [];
    selectedSubdivisionsForReccomendationZone.forEach((ss) => {
      selectSubs.push(JSON.parse(new GeoJSON().writeFeatures([ss])));
    });

    let union = null;
    for (let i = 0; i < selectSubs.length; i++) {
      if (union === null) {
        union = selectSubs[i].features[0].geometry;
      } else {
        union = turf.union(union, selectSubs[i].features[0].geometry);
      }
    }

    const reccomGeometrySource = new VectorSource({
      features: new GeoJSON().readFeatures(union),
      name: 'recommZone'
    });
    const reccomGeometryLayer = new VectorLayer({
      source: reccomGeometrySource,
      name: 'recommZone',
      zIndex: 2000,
      style: new Style({
        stroke: new Stroke({
          color: '#85be37',
          width: 3
        }),
        fill: new Fill({
          color: 'rgba(11,156,49,0.4)'
        })
      })
    });
    refMap.addLayer(reccomGeometryLayer);
    refMap.removeInteraction(selecter);

    const featureUnion = new GeoJSON().readFeature(union);
    const geoJsonFeature = JSON.parse(
      new GeoJSON().writeFeatures([featureUnion])
    );
    geoJsonFeature.features[0].properties = {
      farm_id: selectedRecommZone.features[0].properties.farm_id,
      recommendation_description:
        selectedRecommZone.features[0].properties.recommendation_description,
      recommendation_zone_id:
        selectedRecommZone.features[0].properties.recommendation_zone_id,
      created_by: user.user_id,
      subdivisions_list: selectSubs.map(
        (v) => v.features[0].properties.subdivision_id
      )
    };
    return geoJsonFeature;
  };

  const createRecommendationZone = () => {
    setDrawing(true);

    let unionLimit = null;
    for (let i = 0; i < listSubdivisions.length; i++) {
      if (unionLimit === null) {
        unionLimit = listSubdivisions[i].features[0].geometry;
      } else {
        unionLimit = turf.union(
          unionLimit,
          listSubdivisions[i].features[0].geometry
        );
      }
    }

    const ZoneSource = new VectorSource({
      wrapX: true,
      name: 'recommZone'
    });
    const ZoneLayer = new VectorLayer({
      source: ZoneSource,
      name: 'recommZone'
    });
    const drawer = new Draw({
      source: ZoneSource,
      type: 'Polygon'
    });
    setDraw(drawer);
    refMap.addInteraction(drawer);
    refMap.addLayer(ZoneLayer);

    drawer.on('drawend', (drawned) => {
      const geoJson = JSON.parse(geoJsonObj.writeFeatures([drawned.feature]));
      if (
        !turf.booleanIntersects(
          geoJson.features[0].geometry,
          selectedFarmGeoJson.features[0].geometry
        ) ||
        !turf.booleanIntersects(
          geoJson.features[0].geometry,
          unionLimit.geometry
        )
      ) {
        // removeRecommendationZone();
        removeAllInterections();
        createRecommendationZone();
        return toast(
          <Toast title="Geometria deve ser desenhada dentro da area da fazenda e subdivisoes" />,
          { type: 'error' }
        );
      }
      const intersection = intersect(
        geoJson.features[0].geometry,
        unionLimit.geometry
      );
      const recomList = [];
      recommZoneList.forEach((rz) => {
        if (
          turf.booleanOverlap(
            geoJson.features[0].geometry,
            rz.features[0].geometry
          )
        ) {
          recomList.push(rz.features[0].geometry);
        }
      });
      let diff = null;
      let recomUnion = null;
      if (recomList.length > 0) {
        let first = true;
        for (let i = 0; i < recomList.length; i++) {
          if (first) {
            recomUnion = recomList[i];
            first = false;
          } else {
            recomUnion = turf.union(recomUnion, recomList[i]);
          }
        }
        try {
          diff = turf.difference(intersection, recomUnion);
          geoJson.features[0].geometry = diff.geometry;
        } catch (error) {
          removeAllInterections();
          createRecommendationZone();
          return toast(
            <Toast title="Geometria deve ser desenhada dentro da area da livre fazenda e subdivisoes" />,
            { type: 'error' }
          );
        }
      } else {
        diff = intersection;
        geoJson.features[0].geometry = diff.geometry;
      }

      setDrawendField(true);
      // setRecommZoneDrawed(geoJson);

      const reccomGeometrySource = new VectorSource({
        features: new GeoJSON().readFeatures(geoJson),
        name: 'recommZone'
      });
      const reccomGeometryLayer = new VectorLayer({
        source: reccomGeometrySource,
        name: 'recommZone',
        zIndex: 2000,
        style: new Style({
          stroke: new Stroke({
            color: '#85be37',
            width: 3
          }),
          fill: new Fill({
            color: 'rgba(11,156,49,0.4)'
          })
        })
      });
      refMap.addLayer(reccomGeometryLayer);
      refMap.removeLayer(ZoneLayer);
      refMap.removeInteraction(drawer);
      setDisableDrawerButton(true);
      setSelectedSubdivisionsForReccomendationZone([]);
    });
  };

  const removeDrawer = () => {
    refMap.removeInteraction(draw);
  };

  const drawSubdivision = () => {
    const SubdivisionSource = new VectorSource({
      wrapX: true,
      name: 'subdivision'
    });
    const SubdivisionLayer = new VectorLayer({
      source: SubdivisionSource,
      name: 'subdivision'
    });
    const drawer = new Draw({
      source: SubdivisionSource,
      type: 'Polygon'
    });
    refMap.addInteraction(drawer);
    refMap.addLayer(SubdivisionLayer);
  };
  const displayFeatureInfo = (pixel) => {
    const names = [];
    const highlightStyle = new Style({
      fill: new Fill({
        color: 'rgba(255,255,255,0.7)'
      }),
      stroke: new Stroke({
        color: '#3399CC',
        width: 3
      })
    });

    refMap.forEachFeatureAtPixel(pixel, (feature, layer) => {
      if (!layer) return;
      if (layer.getProperties().name === 'field') {
        const selIndex = geomList.indexOf(feature);
        if (selIndex < 0) {
          feature.setStyle(highlightStyle);
          geomList.push(feature);
          names.push(feature.getProperties().field_name);
          setSelectedFields([...geomList]);
        } else {
          geomList.splice(selIndex, 1);
          feature.setStyle(undefined);
          setSelectedFields([...geomList]);
        }
      }
    });
  };

  const a = (evt) => {
    const { pixel } = evt;
    displayFeatureInfo(pixel);
  };

  const removeEvent = () => {
    setDrawing(false);
    refMap.un('dblclick', a);
    unByKey(a);
  };

  const createSubdivision = async (status) => {
    const fieldTest = [];
    if (status === 'clear') {
      setSelectedFields(...[]);
      let selectDel;
      refMap
        .getInteractions()
        .getArray()
        .forEach((interaction) => {
          if (interaction instanceof Select) {
            selectDel = interaction;
          }
        });
      refMap.removeInteraction(selectDel);
    }
    if (status === 'selectSub') {
      refMap.getLayers().forEach((d) => {
        if (d.get('name') === 'field') {
          d.getSource()
            .getFeatures()
            .forEach((aa) => {
              const writer = new GeoJSON();
              const geoJsonStr = writer.writeFeatures([aa]);
              fieldTest.push(JSON.parse(geoJsonStr));
            });
        }
      });

      const features = [];

      fieldTest.forEach((d) => {
        const newGeo = new GeoJSON().readFeature(d.features[0]);
        features.push(newGeo);
      });

      const subdivisionsSource = new VectorSource({
        features,
        name: 'restrictLayer'
      });

      const subdivisionsLayer = new VectorLayer({
        source: subdivisionsSource,
        style: new Style({
          stroke: new Stroke({
            color: '#fff',
            width: 2,
            lineDash: [4, 8]
          }),
          fill: new Fill({
            color: 'rgba(255,255,255,0.1)'
          })
        }),
        name: 'restrictLayer'
      });
      const select = new Select({
        condition: doubleClick,
        addCondition: (e) => {
          const names = [];
          const highlightStyle = new Style({
            fill: new Fill({
              color: 'rgba(255,255,255,0.7)'
            }),
            stroke: new Stroke({
              color: '#3399CC',
              width: 3
            })
          });

          e.map.forEachFeatureAtPixel(e.pixel, (feature, layer) => {
            if (!layer) return;
            if (layer.getProperties().name === 'field') {
              const selIndex = geomList.indexOf(feature);
              if (selIndex < 0) {
                feature.setStyle(highlightStyle);
                geomList.push(feature);
                names.push(feature.getProperties().field_name);
                setSelectedFields([...geomList]);
              } else {
                geomList.splice(selIndex, 1);
                feature.setStyle(undefined);
                setSelectedFields([...geomList]);
              }
            }
          });
        },
        layers: [subdivisionsLayer]
      });
      refMap.addInteraction(select);

      let dblClickInteraction;
      refMap
        .getInteractions()
        .getArray()
        .forEach((interaction) => {
          if (interaction instanceof DoubleClickZoom) {
            dblClickInteraction = interaction;
          }
        });
      refMap.removeInteraction(dblClickInteraction);
    } else if (status === 'draw') {
      refMap.getInteractions().getArray();

      const startDraw = async () => {
        await removeEvent();
        const SubdivisionSource = new VectorSource({
          wrapX: true,
          name: 'subdivision'
        });
        const SubdivisionLayer = new VectorLayer({
          source: SubdivisionSource,
          name: 'subdivision'
        });
        const drawer = new Draw({
          source: SubdivisionSource,
          type: 'Polygon',
          stopClick: true
        });

        refMap.addInteraction(drawer);
        refMap.addLayer(SubdivisionLayer);

        const geom = [];
        const list = [];
        const listSub = [];
        const result1 = selectedFields.filter(
          (data) => typeof data === 'object'
        );
        result1.forEach((sfield) => {
          const writer = new GeoJSON();
          const geoJsonStr = writer.writeFeatures([sfield], {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
          });
          geom.push(JSON.parse(geoJsonStr));
        });

        setGeomList([...geom[0].features[0].properties.field_id]);

        let union;
        let diff;

        if (geom.length >= 0) {
          geom.forEach((f) => {
            list.push(f.features[0]);
          });
          if (listSubdivisions.length > 0) {
            listSubdivisions.forEach((f) => {
              const vectorSource = new VectorSource({
                features: new GeoJSON().readFeatures(f)
              });
              const writer = new GeoJSON();
              const geoJsonStr = writer.writeFeatures(
                [vectorSource.getFeatures()[0]],
                {
                  dataProjection: 'EPSG:4326',
                  featureProjection: 'EPSG:3857'
                }
              );
              const subParse = JSON.parse(geoJsonStr);
              listSub.push(subParse.features[0]);
            });

            const fieldsPolygon = [];
            const subsPolygon = [];

            list.forEach((d) => {
              fieldsPolygon.push(turf.polygon(d.geometry.coordinates[0]));
            });

            listSub.forEach((d) => {
              subsPolygon.push(turf.polygon(d.geometry.coordinates[0]));
            });
            const fc = turf.featureCollection(list);
            const fcSub = turf.featureCollection(listSub);
            const combine = turf.combine(fc);
            const combineSub = turf.combine(fcSub);
            diff = turf.difference(
              combine.features[0].geometry,
              combineSub.features[0].geometry
            );
            union = diff;
          } else {
            const fc = turf.featureCollection(list);
            const combine = turf.combine(fc);
            union = turf.multiPolygon(combine.features[0].geometry.coordinates);
          }
        }

        // continuar com a funcao de intersection. para definir a subdivisão.
        drawer.on('drawend', (drawerned) => {
          const geoJson = JSON.parse(
            geoJsonObj.writeFeatures([drawerned.feature], {
              dataProjection: 'EPSG:4326',
              featureProjection: 'EPSG:3857'
            })
          );
          let subDivisionIntersection;
          if (union.geometry.type === 'Polygon') {
            subDivisionIntersection = intersect(
              turf.polygon(union.geometry.coordinates),
              turf.polygon(geoJson.features[0].geometry.coordinates)
            );
          } else {
            subDivisionIntersection = intersect(
              turf.multiPolygon(union.geometry.coordinates),
              turf.polygon(geoJson.features[0].geometry.coordinates)
            );
          }
          const intersectGeometrySource = new VectorSource({
            features: new GeoJSON().readFeatures(subDivisionIntersection, {
              dataProjection: 'EPSG:4326',
              featureProjection: 'EPSG:3857'
            }),
            name: 'subdivision'
          });
          const intersectGeometryLayer = new VectorLayer({
            source: intersectGeometrySource,
            name: 'subdivision',
            style: new Style({
              stroke: new Stroke({
                color: '#fff',
                width: 2,
                lineDash: [4, 8]
              })
            })
          });
          refMap.removeInteraction(drawer);
          refMap.removeLayer(SubdivisionLayer);
          refMap.addLayer(intersectGeometryLayer);
          const subDivisionIntersection2 = turf.multiPolygon(
            subDivisionIntersection.geometry.coordinates
          );
          setSubdivision(subDivisionIntersection);
          setDrawendSubdivision(false);
        });
      };

      startDraw();
    }
  };

  const modifyFarm = (geojson) => {
    removeGeometries();
    const features = new GeoJSON().readFeatures(geojson);
    const FarmSource = new VectorSource({
      wrapX: true,
      features,
      name: 'farm'
    });
    const FarmLayer = new VectorLayer({
      source: FarmSource,
      name: 'farm'
    });
    refMap.addLayer(FarmLayer);
    refMap.getView().fit(FarmSource.getExtent(), {
      size: refMap.getSize(),
      maxZoom: 13
    });
    const modifyer = new Modify({
      source: FarmSource
    });
    refMap.addInteraction(modifyer);
    setModify(modifyer);
    setFarm(geojson);
    modifyer.on('modifystart', () => {
      setDrawing(true);
    });
    modifyer.on('modifyend', (drawned) => {
      const modifiedGeoJson = new GeoJSON();
      const modifiedGeom = drawned.features.getArray()[0];
      const geojsonReturn = JSON.parse(
        modifiedGeoJson.writeFeatures([modifiedGeom])
      );
      setFarm(geojsonReturn);
    });
  };

  const modifyWalkOfSubdivision = (mWalkofSubdivision) => {
    removeMappingGeometries();
    const walkFeatures = new GeoJSON().readFeatures(mWalkofSubdivision);
    const walkSource = new VectorSource({
      wrapX: true,
      features: walkFeatures,
      name: 'walk'
    });
    const walkLayer = new VectorLayer({
      source: walkSource,
      name: 'walk',
      style: new Style({
        stroke: new Stroke({
          color: 'yellow',
          width: 3
        })
      })
    });
    refMap.addLayer(walkLayer);
    refMap.getView().fit(walkSource.getExtent(), {
      size: refMap.getSize(),
      maxZoom: 13
    });
    const modifyer = new Modify({
      source: walkSource
    });
    refMap.addInteraction(modifyer);
    setModify(modifyer);
    modifyer.on('modifystart', () => {
      setDrawing(true);
    });
    modifyer.on('modifyend', (drawned) => {
      const modifiedGeoJson = new GeoJSON();
      const modifiedGeom = drawned.features.getArray()[0];
      const geojsonReturn = JSON.parse(
        modifiedGeoJson.writeFeatures([modifiedGeom])
      );

      setEditedWalkOfSubdivision(geojsonReturn);
    });
  };

  const modifySubdivision = (geojson) => {
    // removeFieldGeometries();
    removeAllInterections();
    // removeSubdivisionGeometries();
    const features = new GeoJSON().readFeatures(geojson);
    const SubdivisionSource = new VectorSource({
      wrapX: true,
      features,
      name: 'subdivision'
    });
    const SubdivisionLayer = new VectorLayer({
      source: SubdivisionSource,
      name: 'subdivision',
      style: new Style({
        stroke: new Stroke({
          color: '#d9f3 00',
          width: 2,
          lineDash: [4, 8]
        }),
        fill: new Fill({
          color: 'rgba(255,255,255,0.1)'
        })
      })
    });
    refMap.addLayer(SubdivisionLayer);
    const modifyer = new Modify({
      source: SubdivisionSource
    });
    refMap.addInteraction(modifyer);
    setModify(modifyer);
    setSubdivision(geojson);
    modifyer.on('modifystart', () => {
      setDrawendSubdivisionAndPoint(false);
    });
    modifyer.on('modifyend', (drawned) => {
      const modifiedGeoJson = new GeoJSON();
      const modifiedGeometry = drawned.features.getArray()[0];
      const geoJsonReturn = JSON.parse(
        modifiedGeoJson.writeFeatures([modifiedGeometry])
      );
      const intersection = intersect(
        geoJsonReturn.features[0].geometry,
        farm.features[0].geometry
      );
      geoJsonReturn.features[0].geometry = intersection.geometry;

      let diff = null;
      const subdivisionsList = [];
      listSubdivisions
        .filter(
          (el) =>
            el.features[0].properties.subdivision_id !==
            geoJsonReturn.features[0].properties.subdivision_id
        )
        .forEach((eSubdivision) => {
          if (
            turf.booleanOverlap(
              geoJsonReturn.features[0].geometry,
              eSubdivision.features[0].geometry
            )
          ) {
            subdivisionsList.push(eSubdivision.features[0].geometry);
          }
        });

      let union = null;
      if (subdivisionsList.length > 0) {
        let first = true;

        for (let i = 0; i < subdivisionsList.length; i++) {
          if (first) {
            union = subdivisionsList[i];
            first = false;
          } else {
            union = turf.union(union, subdivisionsList[i]);
          }
        }
        diff = turf.difference(intersection, union);
        geoJsonReturn.features[0].geometry = diff.geometry;
      } else {
        diff = intersection;
        geoJsonReturn.features[0].geometry = diff.geometry;
      }

      const fieldsListOverlap = [];
      listFields.forEach((eField) => {
        // eslint-disable-next-line no-lone-blocks
        {
          fieldsListOverlap.push(eField.features[0].geometry);
        }
      });

      union = null;
      if (fieldsListOverlap.length > 0) {
        let first = true;
        for (let i = 0; i < fieldsListOverlap.length; i++) {
          if (first) {
            union = fieldsListOverlap[i];
            first = false;
          } else {
            union = turf.union(union, fieldsListOverlap[i]);
          }
        }
      }

      diff = intersect(geoJsonReturn.features[0].geometry, union);

      geoJsonReturn.features[0].geometry = diff.geometry;

      const fieldsIntersect = listFields
        .map((f) => {
          if (
            turf.booleanIntersects(
              f.features[0].geometry,
              geoJsonReturn.features[0].geometry
            )
          ) {
            return f.features[0].properties.field_id;
          }
          return '';
        })
        .filter((value) => value !== '' ?? value);
      setSubdivisionFieldsIntersect(fieldsIntersect);
      setSubdivision(geoJsonReturn);
    });
  };

  const modifyField = (geojson) => {
    removeAllInterections();
    const features = new GeoJSON().readFeatures(geojson);
    const FieldSource = new VectorSource({
      wrapX: true,
      features,
      name: 'field'
    });
    const FieldLayer = new VectorLayer({
      source: FieldSource,
      name: 'field',
      style: new Style({
        stroke: new Stroke({
          color: 'yellow',
          width: 1
        }),
        fill: new Fill({
          color: 'rgba(255,255,0,0.1)'
        })
      })
    });
    refMap.addLayer(FieldLayer);
    const modifyer = new Modify({
      source: FieldSource
    });
    refMap.addInteraction(modifyer);
    setModify(modifyer);
    setField(geojson);
    modifyer.on('modifystart', () => {
      setDrawing(true);
    });
    modifyer.on('modifyend', (drawned) => {
      const modifiedGeoJson = new GeoJSON();
      const modifiedGeometry = drawned.features.getArray()[0];
      const geoJsonReturn = JSON.parse(
        modifiedGeoJson.writeFeatures([modifiedGeometry])
      );
      const intersection = intersect(
        geoJsonReturn.features[0].geometry,
        farm.features[0].geometry
      );
      geoJsonReturn.features[0].geometry = intersection.geometry;

      let diff = null;
      const fieldsList = [];
      listFields
        .filter(
          (el) =>
            el.features[0].properties.field_id !==
            geoJsonReturn.features[0].properties.field_id
        )
        .forEach((eField) => {
          if (
            turf.booleanOverlap(
              geoJsonReturn.features[0].geometry,
              eField.features[0].geometry
            )
          ) {
            fieldsList.push(eField.features[0].geometry);
          }
        });

      let union = null;
      if (fieldsList.length > 0) {
        let first = true;

        for (let i = 0; i < fieldsList.length; i++) {
          if (first) {
            union = fieldsList[i];
            first = false;
          } else {
            union = turf.union(union, fieldsList[i]);
          }
        }
        diff = turf.difference(intersection, union);
        geoJsonReturn.features[0].geometry = diff.geometry;
      } else {
        diff = intersection;
        geoJsonReturn.features[0].geometry = diff.geometry;
      }
      setField(geoJsonReturn);
    });
  };

  const createPointOfSubdivision = () => {
    const PointSource = new VectorSource({
      wrapX: true,
      name: 'points'
    });

    const PointLayer = new VectorLayer({
      source: PointSource,
      name: 'points'
    });

    const drawer = new Draw({
      source: PointSource,
      type: 'Point'
    });

    setDraw(drawer);

    refMap.addInteraction(drawer);
    refMap.addLayer(PointLayer);
    let point;
    drawer.on('drawend', (drawned) => {
      refMap.removeInteraction(drawer);
      const geoJson = JSON.parse(
        geoJsonObj.writeFeatures([drawned.feature], {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857'
        })
      );
      if (
        !turf.booleanWithin(geoJson.features[0].geometry, subdivision.geometry)
      ) {
        refMap.removeLayer(PointLayer);
        createPointOfSubdivision();
        return;
      }
      point = parseCoordinatesPointTo3857(geoJson);
      setDrawendSubdivisionAndPoint(false);
      setPointOfSubdivision(point);
    });
  };

  const centerDepthPointSubdivision = (geojson) => {
    const Source = new VectorSource({
      wrapX: true,
      features: new GeoJSON().readFeatures(geojson)
    });
    const geo = Source.getExtent();
    return olExtent.getCenter(geo);
  };

  const renderFieldMappings = (geoJsonFieldMappings) => {
    if (geoJsonFieldMappings.length === 0) return;
    const features = geoJsonFieldMappings.map((geoJsonField) => {
      return new GeoJSON().readFeatures(geoJsonField)[0];
    });

    const fieldMappingsSource = new VectorSource({
      wrapX: true,
      features,
      name: 'walk'
    });

    const fieldMappingsLayer = new VectorLayer({
      source: fieldMappingsSource,
      style: new Style({
        stroke: new Stroke({
          color: 'yellow',
          width: 3
        })
      }),
      name: 'walk'
    });

    refMap.addLayer(fieldMappingsLayer);
    readLayers();
  };

  const renderWMSTile = (geoJson, date) => {
    const parseDate = date.split('/');
    removeWMSLayer();
    const features = new GeoJSON().readFeatures(geoJson);
    const wktPolygon = new WKT();
    const wktTile = wktPolygon.writeFeatures(features);
    const sentinelTile = new TileLayer({
      zIndex: 50,
      preload: Infinity,
      visible: true,
      source: new TileWMS({
        url: `https://services.sentinel-hub.com/ogc/wms/${process.env.REACT_APP_MAP_SENTINEL_KEY}`,
        params: {
          TYPE: satellite,
          MAXCC: maxClouds,
          CRS: 'EPSG:3857',
          WIDTH: 512,
          HEIGHT: 512,
          PRESET: tilePreset,
          LAYERS: tileType,
          TIME: `${parseDate[2]}-${parseDate[1]}-${parseDate[0]}`,
          GEOMETRY: wktTile,
          SHOWLOGO: false
        },
        serverType: 'geoserver',
        ratio: 1
      }),
      name: 'WMS-LAYER'
    });
    refMap.addLayer(sentinelTile);
  };

  const combineGeometriesOld = (geoJsonArray) => {
    const allFeatures = [];
    geoJsonArray.forEach((geoJson) => {
      geoJson.features.forEach((feature) => {
        allFeatures.push(feature);
      });
    });

    let combinedFeature = allFeatures[0];
    for (let i = 1; i < allFeatures.length; i++) {
      combinedFeature = turf.union(combinedFeature, allFeatures[i]);
    }

    return combinedFeature.geometry;
  };

  const convertToWKTOld = (geometry) => {
    const geoJsonFormat = new GeoJSON();
    const feature = geoJsonFormat.readFeature({
      type: 'Feature',
      geometry,
      properties: {}
    });

    const wkt = new WKT();
    return wkt.writeFeature(feature);
  };

  const convertToWKT = (geo) => {
    const geoJsonFormat = new GeoJSON();
    const feature = geoJsonFormat.readFeatures(geo);

    const wkt = new WKT();
    return wkt.writeFeatures(feature);
  };

  const renderWMSTileMultipleFields = async (geoJson, date) => {
    const parseDate = date.split('/');

    const createTileLayer = (geometry) => {
      const wktGeometry = convertToWKT(geometry);

      return new TileLayer({
        zIndex: 50,
        preload: Infinity,
        visible: true,
        source: new TileWMS({
          url: `https://services.sentinel-hub.com/ogc/wms/${process.env.REACT_APP_MAP_SENTINEL_KEY}`,
          params: {
            TYPE: satellite,
            MAXCC: maxClouds,
            CRS: 'EPSG:3857',
            WIDTH: 256,
            HEIGHT: 256,
            PRESET: tilePreset,
            LAYERS: tileType,
            TIME: `${parseDate[2]}-${parseDate[1]}-${parseDate[0]}`,
            showlogo: false,
            GEOMETRY: wktGeometry
          },
          serverType: 'geoserver',
          ratio: 1
        }),
        name: 'WMS-LAYER'
      });
    };

    const tileLayersPromises = geoJson.map((geometry) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          try {
            const sentinelTile = createTileLayer(geometry);
            refMap.addLayer(sentinelTile);
            resolve();
          } catch (error) {
            console.error(error);
            resolve();
          }
        }, 1000 * 2);
      });
    });

    await Promise.all(tileLayersPromises);
  };

  const renderWMSTileFieldFilters = (geoJson, date) => {
    const features = new GeoJSON().readFeatures(geoJson);
    const wktPolygon = new WKT();
    const wktTile = wktPolygon.writeFeatures(features);
    const sentinelTile = new TileLayer({
      zIndex: 50,
      preload: Infinity,
      visible: true,
      source: new TileWMS({
        url: `https://services.sentinel-hub.com/ogc/wms/${process.env.REACT_APP_MAP_SENTINEL_KEY}`,
        params: {
          TYPE: satellite,
          MAXCC: maxClouds,
          CRS: 'EPSG:3857',
          WIDTH: 512,
          HEIGHT: 512,
          PRESET: tilePreset,
          LAYERS: tileType,
          TIME: `${date[0]}-${date[1]}`,
          GEOMETRY: wktTile,
          SHOWLOGO: false
        },
        serverType: 'geoserver',
        ratio: 1
      }),
      name: 'WMS-LAYER'
    });
    refMap.addLayer(sentinelTile);
  };

  const pointCollectionGenerate = (subdivisions) => {
    let geometrylist = [];
    refMap.getLayers()?.forEach((layer) => {
      if (
        layer.get('name') === 'restrictionSubdivisionLayer' ||
        layer.get('name') === 'restrictLayer'
      ) {
        geometrylist.push(layer);
      }
    });
    geometrylist.forEach((layer) => {
      refMap.removeLayer(layer);
    });

    const features = subdivisions.map((value) =>
      new GeoJSON().readFeature(value.features[0])
    );
    const subdivisionsSource = new VectorSource({
      features,
      name: 'restrictLayer'
    });
    const subdivisionsLayer = new VectorLayer({
      source: subdivisionsSource,
      style: new Style({
        stroke: new Stroke({
          color: '#fff',
          width: 2,
          lineDash: [4, 8]
        }),
        fill: new Fill({
          color: 'rgba(255,255,255,0.1)'
        })
      }),
      name: 'restrictLayer'
    });

    const select = new Select({
      condition: pointerMove,
      layers: [subdivisionsLayer]
    });
    setSelecter(select);
    refMap.addInteraction(select);
    refMap.addLayer(subdivisionsLayer);

    const WalkSource = new VectorSource({
      wrapX: true,
      name: 'walk'
    });

    const WalkLayer = new VectorLayer({
      source: WalkSource,
      name: 'walk',
      style: new Style({
        stroke: new Stroke({
          color: 'yellow',
          width: 3
        })
      })
    });

    refMap.addLayer(WalkLayer);
    let restrictionSubdivisionLayer = null;
    let generatedPoints = [];

    const drawer = new Draw({
      condition: (e) => {
        const featuresCollectionPoints = refMap.getFeaturesAtPixel(e.pixel, {
          layerFilter(layer) {
            return layer === restrictionSubdivisionLayer;
          }
        });
        if (
          featuresCollectionPoints != null &&
          featuresCollectionPoints.length > 0
        ) {
          return true;
        }
        return false;
      },
      source: WalkSource,
      type: 'LineString'
    });

    setDraw(drawer);
    let geoJsonSubdivision = {};

    drawer.on('drawend', (drawned) => {
      refMap.removeInteraction(drawer);
      restrictionSubdivisionLayer.setVisible(false);
      subdivisionsLayer.setVisible(false);

      const geoJson = JSON.parse(geoJsonObj.writeFeatures([drawned.feature]));
      const line = parseCoordinatesLineStringTo4326(geoJson);

      const length = turf.length(line.features[0], { units: 'kilometers' });
      const cPointsDistance = length / (Number(qtdPoints) - 1);

      if (cPointsDistance > 0 && cPointsDistance !== null) {
        for (let init = 0; init <= length; init += cPointsDistance) {
          const along = turf.along(line.features[0], init, {
            units: 'kilometers'
          });
          along.geometry.coordinates = proj4(
            'EPSG:4326',
            'EPSG:3857',
            along.geometry.coordinates
          );

          generatedPoints.push(along);
          const vectrSource = new VectorSource({
            features: new GeoJSON().readFeatures(along),
            name: 'points'
          });
          const vectrLayer = new VectorLayer({
            source: vectrSource,
            name: 'points'
          });
          refMap.addLayer(vectrLayer);
        }
      }

      const parsedGeoJson = generatedPoints.map((value) => {
        const fieldIntersect = listFields
          .map((f) => {
            if (
              turf.booleanIntersects(f.features[0].geometry, value.geometry)
            ) {
              return f.features[0].properties.field_id;
            }
            return '';
          })
          .filter((v) => v !== '' ?? v)[0];
        value.properties = {
          type: 'REFERENCIA_COLETA',
          subdivision_id:
            geoJsonSubdivision.features[0].properties.subdivision_id,
          farm_id: geoJsonSubdivision.features[0].properties.farm_id,
          field_id: fieldIntersect,
          automatically_generated: false,
          created_by: geoJsonSubdivision.features[0].properties.created_by,
          description: 'COLETA'
        };
        return {
          type: 'FeatureCollection',
          features: [value]
        };
      });
      generatedCollectionPointsQtd.push(parsedGeoJson);
      generatedPoints = [];

      if (restrictionSubdivisionLayer !== null) {
        refMap.getLayers().forEach((layer) => {
          if (
            layer.get('name') === 'restrictionSubdivisionLayer' ||
            layer.get('name') === 'restrictLayer'
          ) {
            geometrylist.push(layer);
          }
        });
        geometrylist.forEach((layer) => {
          refMap.removeLayer(layer);
        });
      }

      pointCollectionGenerate(subdivisions);
    });

    select.on('select', (e) => {
      if (e.selected.length < 1) return;

      const geoJson = JSON.parse(geoJsonObj.writeFeatures([e.selected[0]]));
      geoJsonSubdivision = geoJson;
      const restrictionSubdivisionSource = new VectorSource({
        features: new GeoJSON().readFeatures(geoJson)
      });

      restrictionSubdivisionLayer = new VectorLayer({
        source: restrictionSubdivisionSource,
        name: 'restrictionSubdivisionLayer',
        zIndex: 999,
        style: new Style({
          stroke: new Stroke({
            color: '#fff',
            width: 2,
            lineDash: [4, 8]
          }),
          fill: new Fill({
            color: 'rgba(255,255,255,0.1)'
          })
        })
      });

      refMap.addLayer(restrictionSubdivisionLayer);
      refMap.addInteraction(drawer);
    });
  };

  const pointCollectionGenerateAlleatory = (subdivisions) => {
    let layerList = [];
    refMap.getLayers()?.forEach((layer) => {
      if (
        layer.get('name') === 'restrictionSubdivisionLayer' ||
        layer.get('name') === 'restrictLayer'
      ) {
        layerList.push(layer);
      }
    });
    layerList.forEach((layer) => {
      refMap.removeLayer(layer);
    });

    const features = subdivisions.map((value) =>
      new GeoJSON().readFeature(value.features[0])
    );
    const subdivisionsSource = new VectorSource({
      features,
      name: 'restrictLayer'
    });

    const subdivisionsLayer = new VectorLayer({
      source: subdivisionsSource,
      style: new Style({
        stroke: new Stroke({
          color: '#fff',
          width: 2,
          lineDash: [4, 8]
        }),
        fill: new Fill({
          color: 'rgba(255,255,255,0.1)'
        })
      }),
      name: 'restrictLayer'
    });

    const select = new Select({
      condition: pointerMove,
      layers: [subdivisionsLayer]
    });
    setSelecter(select);
    refMap.addInteraction(select);
    refMap.addLayer(subdivisionsLayer);

    const PointSource = new VectorSource({
      wrapX: true,
      name: 'points'
    });

    const PointLayer = new VectorLayer({
      source: PointSource,
      zIndex: 998,
      name: 'points'
    });

    refMap.addLayer(PointLayer);
    let restrictionSubdivisionLayer = null;

    const drawer = new Draw({
      condition: (e) => {
        const feat = refMap.getFeaturesAtPixel(e.pixel, {
          layerFilter(layer) {
            return layer === restrictionSubdivisionLayer;
          }
        });
        if (feat != null && feat.length > 0) {
          return true;
        }
        return false;
      },
      source: PointSource,
      type: 'Point'
    });

    setDraw(drawer);
    let generatedPoints = [];
    let geoJsonSubdivision = {};
    drawer.on('drawend', (drawned) => {
      const geoJson = JSON.parse(geoJsonObj.writeFeatures([drawned.feature]));

      generatedPoints.push(geoJson.features[0]);
      const parsedGeoJson = generatedPoints.map((value) => {
        const fieldIntersect = listFields
          .map((f) => {
            if (
              turf.booleanIntersects(f.features[0].geometry, value.geometry)
            ) {
              return f.features[0].properties.field_id;
            }
            return '';
          })
          .filter((v) => v !== '' ?? v)[0];
        value.properties = {
          type: 'REFERENCIA_COLETA',
          subdivision_id:
            geoJsonSubdivision.features[0].properties.subdivision_id,
          farm_id: geoJsonSubdivision.features[0].properties.farm_id,
          field_id: fieldIntersect,
          automatically_generated: false,
          created_by: geoJsonSubdivision.features[0].properties.created_by,
          description: 'COLETA'
        };
        return {
          type: 'FeatureCollection',
          features: [value]
        };
      });
      generatedCollectionPoints.push(geoJson);
      generatedPoints = [];
    });

    const geometrylist = [];
    select.on('select', (e) => {
      if (e.selected.length < 1) return;
      if (restrictionSubdivisionLayer !== null) {
        refMap.getLayers().forEach((layer) => {
          if (layer.get('name') === 'restrictionSubdivisionLayer') {
            geometrylist.push(layer);
          }
        });
        geometrylist.forEach((layer) => {
          refMap.removeLayer(layer);
        });
      }

      const geoJson = JSON.parse(geoJsonObj.writeFeatures([e.selected[0]]));
      geoJsonSubdivision = geoJson;
      const restrictionSubdivisionSource = new VectorSource({
        features: new GeoJSON().readFeatures(geoJson)
      });

      restrictionSubdivisionLayer = new VectorLayer({
        source: restrictionSubdivisionSource,
        name: 'restrictionSubdivisionSource',
        zIndex: 999,
        style: new Style({
          stroke: new Stroke({
            color: '#fff',
            width: 2,
            lineDash: [4, 8]
          }),
          fill: new Fill({
            color: 'rgba(255,255,255,0.1)'
          })
        })
      });
      refMap.addLayer(restrictionSubdivisionLayer);

      refMap.addInteraction(drawer);
    });
  };

  const pointCollectionGenerateEquidistant = (subdivisions, collectionType) => {
    let layerlist = [];
    refMap.getLayers()?.forEach((layer) => {
      if (
        layer.get('name') === 'restrictionSubdivisionLayer' ||
        layer.get('name') === 'restrictLayer'
      ) {
        layerlist.push(layer);
      }
    });
    layerlist.forEach((layer) => {
      refMap.removeLayer(layer);
    });

    const features = subdivisions.map((value) =>
      new GeoJSON().readFeature(value.features[0])
    );
    const subdivisionsSource = new VectorSource({
      features,
      name: 'restrictLayer'
    });

    const subdivisionsLayer = new VectorLayer({
      source: subdivisionsSource,
      style: new Style({
        stroke: new Stroke({
          color: '#fff',
          width: 2,
          lineDash: [4, 8]
        }),
        fill: new Fill({
          color: 'rgba(255,255,255,0.1)'
        })
      }),
      name: 'restrictLayer'
    });

    const select = new Select({
      condition: pointerMove,
      layers: [subdivisionsLayer]
    });
    setSelecter(select);
    refMap.addInteraction(select);
    refMap.addLayer(subdivisionsLayer);

    const WalkSource = new VectorSource({
      wrapX: true,
      name: 'walk'
    });

    const WalkLayer = new VectorLayer({
      source: WalkSource,
      name: 'walk',
      style: new Style({
        stroke: new Stroke({
          color: 'yellow',
          width: 3
        })
      })
    });

    refMap.addLayer(WalkLayer);
    let restrictionSubdivisionLayer = null;
    let generatedPoints = [];

    const drawer = new Draw({
      condition: (e) => {
        // const coords = e.coordinate;
        const featuresEqueidistant = refMap.getFeaturesAtPixel(e.pixel, {
          layerFilter(layer) {
            return layer === restrictionSubdivisionLayer;
          }
        });
        if (featuresEqueidistant != null && featuresEqueidistant.length > 0) {
          return true;
        }
        return false;
      },
      source: WalkSource,
      type: 'LineString'
    });

    setDraw(drawer);
    let geoJsonSubdivision = {};

    drawer.on('drawend', (drawned) => {
      refMap.removeInteraction(drawer);
      subdivisionsLayer.setVisible(false);
      const geoJson = JSON.parse(geoJsonObj.writeFeatures([drawned.feature]));
      const line = parseCoordinatesLineStringTo4326(geoJson);

      const length = turf.length(line.features[0], { units: 'meters' });

      for (let init = 0; init <= length; init += Number(pointsDistance)) {
        const along = turf.along(line.features[0], init, {
          units: 'meters'
        });

        along.geometry.coordinates = proj4(
          'EPSG:4326',
          'EPSG:3857',
          along.geometry.coordinates
        );

        generatedPoints.push(along);

        const vectrSource = new VectorSource({
          features: new GeoJSON().readFeatures(along),
          name: 'points'
        });
        const vectrLayer = new VectorLayer({
          source: vectrSource,
          name: 'points'
        });

        refMap.addLayer(vectrLayer);
      }

      const parsedGeoJson = generatedPoints.map((value) => {
        const fieldIntersect = listFields
          .map((f) => {
            if (
              turf.booleanIntersects(f.features[0].geometry, value.geometry)
            ) {
              return f.features[0].properties.field_id;
            }

            return '';
          })
          .filter((v) => v !== '' ?? v)[0];
        value.properties = {
          type: 'REFERENCIA_COLETA',
          subdivision_id:
            geoJsonSubdivision.features[0].properties.subdivision_id,
          farm_id: geoJsonSubdivision.features[0].properties.farm_id,
          field_id: fieldIntersect,
          automatically_generated: false,
          created_by: geoJsonSubdivision.features[0].properties.created_by,
          description: 'COLETA'
        };
        return {
          type: 'FeatureCollection',
          features: [value]
        };
      });
      generatedCollectionPointsQtd.push(parsedGeoJson);
      generatedPoints = [];

      refMap.getLayers()?.forEach((layer) => {
        if (
          layer.get('name') === 'restrictionSubdivisionLayer' ||
          layer.get('name') === 'restrictLayer'
        ) {
          layerlist.push(layer);
        }
      });
      layerlist.forEach((layer) => {
        refMap.removeLayer(layer);
      });

      pointCollectionGenerateEquidistant(subdivisions);
    });

    select.on('select', (e) => {
      if (e.selected.length < 1) return;

      const geoJson = JSON.parse(geoJsonObj.writeFeatures([e.selected[0]]));
      geoJsonSubdivision = geoJson;
      const restrictionSubdivisionSource = new VectorSource({
        features: new GeoJSON().readFeatures(geoJson)
      });

      restrictionSubdivisionLayer = new VectorLayer({
        source: restrictionSubdivisionSource,
        name: 'restrictionSubdivisionLayer',
        zIndex: 999,
        style: new Style({
          stroke: new Stroke({
            color: '#fff',
            width: 2,
            lineDash: [4, 8]
          }),
          fill: new Fill({
            color: 'rgba(255,255,255,0.1)'
          })
        })
      });

      refMap.addLayer(restrictionSubdivisionLayer);
      refMap.addInteraction(drawer);
    });
  };

  const updateSizeMap = () => {
    refMap.updateSize();
  };

  const mapScale = () => {
    return document
      .getElementsByClassName('ol-scale-text')[0]
      .innerHTML.split(':')[1];
  };

  const updateMapScale = (scale) => {
    const resolution = 300;
    const secretNumber = 3.5714286;
    const scaleResolution =
      scale /
      getPointResolution(
        'EPSG:3857',
        resolution / (resolution / secretNumber),
        refMap.getView().getCenter()
      );
    scaleLine.setDpi(resolution);
    refMap.updateSize();
    refMap.getView().setResolution(scaleResolution);
  };

  return (
    <MapContext.Provider
      value={{
        newMap,
        refMap,
        setMapCenter,
        createFarm,
        setRefMap,
        drawing,
        setDrawing,
        stateOptions,
        setStateOptions,
        farm,
        renderFarm,
        modifyFarm,
        removeAllInterections,
        viewFields,
        setViewFields,
        addFields,
        addingField,
        setAddingField,
        removeMappingGeometries,
        field,
        setField,
        drawendField,
        farms,
        setFarms,
        renderFields,
        modifyField,
        selectedFarmId,
        setSelectedFarmId,
        removeGeometries,
        removeFieldGeometries,
        listFields,
        setListFields,
        renderFieldFromShapefile,
        renderMapping,
        viewMapping,
        modifyMappingField,
        editedMapping,
        setEditedMapping,
        dateCarrousel,
        setDateCarrousel,
        createWalk,
        readLayers,
        hideLayer,
        activeLayers,
        setActiveLayers,
        createSubdivision,
        subdivision,
        setSubdivision,
        drawendSubdivision,
        setDrawendSubdivision,
        listSubdivisions,
        setListSubdivisions,
        subdivisionFieldsIntersect,
        createPointOfSubdivision,
        drawendSubdivisionAndPoint,
        setDrawendSubdivisionAndPoint,
        pointOfSubdivision,
        setPointOfSubdivision,
        removeSubdivisionGeometries,
        renderSubdivision,
        modifySubdivision,
        selectedFieldToTimeline,
        setSelectedFieldToTimeline,
        renderSubdivisions,
        renderFieldMappings,
        createWalkSubdivision,
        walkOfSubdivision,
        setWalkOfSubdivision,
        modifyWalkOfSubdivision,
        editedWalkOfSubdivision,
        setEditedWalkOfSubdivision,
        renderWMSTile,
        getBboxOfGeometry,
        removeWMSLayer,
        createMapping,
        removeFarmLayer,
        farmMapping,
        setFarmMapping,
        renderDepthPoints,
        removePointGeometries,
        controlState,
        setControlState,
        renderShapefileOfCard,
        importedShapefileName,
        setImportedShapefileName,
        pointCollectionGenerate,
        pointCollectionGenerateAlleatory,
        pointCollectionGenerateEquidistant,
        generatedCollectionPoints,
        setGeneratedCollectionPoints,
        createWalkSubdivisions,
        generatedMappingsSubdivisions,
        setGeneratedMappingsSubdivisions,
        addSwipe,
        legendResultsValue,
        setLegendResultsValue,
        renderSubdivisionsWithColors,
        selectedFarmHa,
        setSelectedFarmHa,
        selectedFarmGeoJson,
        setSelectedFarmGeoJson,
        removeGeometriesColoredSubdivisions,
        renderedMaps,
        setRenderedMaps,
        renderedMapEditId,
        setRenderedMapEditId,
        resetMappings,
        removeRestrictionLayers,
        swipeActived,
        setSwapActived,
        removeGeometriesSwipe,
        activedSwipe,
        btnColor,
        setBtnColor,
        renderCollectionPoints,
        maxClouds,
        setMaxClouds,
        satellite,
        setSatellite,
        raster,
        recommMade,
        setRecommMade,
        maptiler,
        refMap1,
        setRefMap1,
        refMap2,
        setRefMap2,
        refMap3,
        setRefMap3,
        refMap4,
        setRefMap4,
        zoomField,
        showChat,
        setShowChat,
        createRecommendationZone,
        showButtonCarousel,
        setShowButtonCarousel,
        showButtonChat,
        setShowButtonChat,
        renderWMSTileFieldFilters,
        removeRecommendationZone,
        disableDrawerButton,
        setDisableDrawerButton,
        tileType,
        tilePreset,
        setTileType,
        setTilePreset,
        legendResultsValue1,
        setLegendResultsValue1,
        legendResultsValue2,
        setLegendResultsValue2,
        legendResultsValue3,
        setLegendResultsValue3,
        legendResultsValue4,
        setLegendResultsValue4,
        selectedFields,
        setSelectedFields,
        drawSubdivision,
        editSub,
        setEditSub,
        geomList,
        renderSubdivisionsWithColorsMapRef1,
        renderSubdivisionsWithColorsMapRef2,
        renderSubdivisionsWithColorsMapRef3,
        renderSubdivisionsWithColorsMapRef4,
        renderFieldsNoLabel,
        recommZoneId,
        setRecommZoneId,
        swipeShow,
        setSwipeShow,
        removeSwipe,
        editZoneId,
        setEditZoneId,
        recommZoneDrawed,
        // setRecommZoneDrawed,
        editRecommendationZone,
        removeEvent,
        renderRecommendationZone,
        recommZoneList,
        setRecommZoneList,
        selectedRecommZone,
        setSelectedRecommZone,
        removeDrawer,
        legendRecommendationValue,
        setLegendRecommendationValue,
        recommendationId,
        setRecommendationId,
        renderRecommendationZoneWithColors,
        recommendationZoneId,
        setRecommendationZoneId,
        removeColoredRecommendationZone,
        renderRecommendationZones,
        renderSubdivisionFromShapefile,
        centerDepthPointSubdivision,
        renderDepthPointFromShapeFile,
        reports,
        setReports,
        reportEditId,
        setReportEditId,
        disableTileMap,
        parameterResults,
        setParameterResults,
        renderPoint,
        setFarmStyle,
        croquis,
        setCroquis,
        renderSubdivisionsNoLabel,
        qtdPoints,
        setQtdPoints,
        generatedCollectionPointsQtd,
        pointsDistance,
        setPointsDistance,
        setGeneratedCollectionPointsQtd,
        updateSizeMap,
        mapScale,
        offSetHeigth,
        setOffSetHeigth,
        getHorizontalLengthOfGeometry,
        resolution,
        setResolution,
        scale,
        setScale,
        updateMapScale,
        scaleLine,
        tableResults,
        setTableResults,
        zoomInGeometry,
        farmName,
        setFarmName,
        orientation,
        setOrientation,
        renderWMSTileMultipleFields,
        selectedFieldsToNDVI,
        setSelectedFieldsToNDVI,
        createRecommendationZoneSelect,
        selectedSubdivisionsForReccomendationZone,
        setSelectedSubdivisionsForReccomendationZone,
        finalizeCreateRecommendationZoneSelect,
        recommDepth,
        setRecommDepth,
        recommFertilizer,
        setRecommFertilizer,
        recommName,
        setRecommName,
        recommType,
        setRecommType,
        recommMeasurementUnit,
        setRecommMeasurementUnit,
        recommDose,
        setRecommDose,
        recommendationCompleted,
        setRecommendationCompleted,
        finalizeEditRecommendationZone,
        ownerName,
        setOwnerName,
        contactList,
        setContactList,
        chatList,
        setChatList,
        disableQueryNotification,
        setDisableQueryNotification,
        recommendationMapSubtitle,
        setRecommendationMapSubtitle,
        parseCoordinates,
        recommendationsZoneList,
        setRecommendationsZoneList,
        renderRecommendationZoneWithColorsMapRef1,
        renderRecommendationZoneWithColorsMapRef2,
        renderRecommendationZoneWithColorsMapRef3,
        renderRecommendationZoneWithColorsMapRef4,
        legendRecommendationValue1,
        setLegendRecommendationValue1,
        legendRecommendationValue2,
        setLegendRecommendationValue2,
        legendRecommendationValue3,
        setLegendRecommendationValue3,
        legendRecommendationValue4,
        setLegendRecommendationValue4,
        recommendationMapSubtitle1,
        setRecommendationMapSubtitle1,
        recommendationMapSubtitle2,
        setRecommendationMapSubtitle2,
        recommendationMapSubtitle3,
        setRecommendationMapSubtitle3,
        recommendationMapSubtitle4,
        setRecommendationMapSubtitle4,
        fertilize,
        setFertilize,
        buttonRegisterFarmDisable,
        setButtonRegisterFarmDisable
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

function useMap() {
  const context = useContext(MapContext);

  if (!context) {
    throw new Error('useMap must be used within an MapProvider');
  }

  return context;
}
export { MapProvider, useMap };
